import React from 'react';
import { Table, Collapse } from 'antd';
import { roundToFirstNonZero } from '../../utils/util';

const { Column } = Table;

/**
 * @module components/Comparison/PlatformsAverageTable
 *
 * @description
 * PlatformsAverageTable component displays a summary table with average metrics data across all designs per platform.
 * It also gets average of a metric across all platforms
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @param {Array<Object>} props.data - The table data, where each object represents a row with metrics and platform data.
 * @param {Array<string>} props.platformUnion - List of platform names to display as columns.
 * @param {string} props.variant - The variant type.
 * @param {Function} props.onCellClick - Function to handle click events on a table cell.
 * @param {boolean} props.loading - Boolean to indicate if the table is in a loading state.
 * @param {string} [props.title="Summary Table"] - Title for the table.
 *
 * @returns {React.Element} A table displaying platform averages and metrics.
 *
 * @example
 * const data = [
 *   { metrics: "cts__cpu__total", summary: 0.123, asap7: 0.456, gf180: 0.789 },
 *   { metrics: "cts__cpu__total", summary: 0.987, asap7: 0.654, gf180: 0.321 },
 * ];
 * const platformUnion = ["asap7", "gf180"];
 *
 * <PlatformsAverageTable
 *   data={data}
 *   platformUnion={platformUnion}
 *   variant="base"
 *   onCellClick={(record) => console.log(record)}
 *   loading={false}
 * />
 */
const PlatformsAverageTable = ({
  data,
  platformUnion,
  variant,
  onCellClick,
  loading,
  title = 'Summary Table',
}) => {
  console.log('avg table data', data);
  return (
    <Table
      key='average'
      dataSource={data}
      pagination={false}
      bordered
      size='large'
      scroll={{
        x: 'calc(700px + 50%)',
        y: '40vw',
      }}
      loading={loading}
    >
      <Column
        title='Metrics'
        dataIndex='metrics'
        key='metrics'
        fixed='left'
        width={0}
      />
      <Column
        title='Summary'
        dataIndex='summary'
        key='summary'
        fixed='left'
        width={0}
        render={text => {
          return (
            <div style={{ textAlign: 'center' }}>
              {roundToFirstNonZero(text)}
            </div>
          );
        }}
      />
      {platformUnion?.map((platform, index) => (
        <Column
          width={0}
          title={platform}
          dataIndex={platform}
          key={platform}
          render={text => {
            return (
              <div key={index} style={{ textAlign: 'center' }}>
                {roundToFirstNonZero(text)}
              </div>
            );
          }}
        />
      ))}
    </Table>
  );
};

export default PlatformsAverageTable;

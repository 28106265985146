/**
 * @module components/MetricTrends/CustomTooltip
 *
 * @description
 * This module exports the `CustomTooltip` component used to display custom tooltips in charts.
 * It shows formatted date and metric values when hovering over chart elements.
 *
 * @see {@link ./CustomTooltip.css} for styles used by this component.
 */
import React from 'react';
import { format } from 'date-fns';

const style = {
  padding: 6,
  backgroundColor: '#fff',
  border: '1px solid #ccc',
};

/**
 * CustomTooltip component for displaying tooltip data in charts.
 * This component is typically used with (antd) chart libraries to show data points on hover.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.active - Indicates if the tooltip is active (i.e., visible).
 * @param {Array} props.payload - An array of data points for the hovered item. Each data point contains `payload`, `dataKey`, and `value`.
 *
 * @returns {React.Element|null} - Returns the tooltip markup if `active` and `payload` are present, otherwise returns null.
 *
 * @example
 * const payload = [{ payload: { date: "2024-09-07", value: 10 }, dataKey: "Metric A", value: 10 }];
 * <CustomTooltip active={true} payload={payload} />
 */
const CustomTooltip = props => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    console.debug('payload is', payload);
    const currData = payload && payload.length ? payload[0].payload : null;
    return (
      <div className='area-chart-tooltip' style={style}>
        <p>
          {'date : '}
          {currData ? format(new Date(currData.date), 'yyyy-MM-dd') : ' -- '}
        </p>
        {payload?.map((entry, index) => (
          <p key={index}>
            <em>{entry ? entry.dataKey : ' -- '}</em>
            {': '}
            <em>{entry ? entry.value : ' -- '}</em>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;

/**
 * @module features/auth/authSlice
 *
 * @description
 * Redux slice for handling authentication state, including login, token management, and logout.
 * Stores the user's authentication data and manages side effects such as token expiration.
 */
import { createSlice } from '@reduxjs/toolkit';
import { loginUser } from './authAction';
import jwtDecode from 'jwt-decode';

const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null;
const storedState = localStorage.getItem('userInfo');
const userInfo = storedState ? JSON.parse(storedState) : null;

/**
 * The initial state of the auth slice.
 *
 * @typedef {Object} AuthState
 * @property {boolean} loading - Indicates whether the login process is in progress.
 * @property {Object|null} userInfo - Stores the user information (decoded from JWT or from API response).
 * @property {string|null} userToken - The JWT token for authenticated sessions.
 * @property {string|null} error - Stores error messages, if any, during the login process.
 * @property {boolean} success - Indicates whether the login process was successful.
 */
const initialState = {
  loading: false,
  userInfo, // for user object
  userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

/**
 * Redux slice for handling authentication state, including login and logout.
 *
 * @slice
 * @name auth
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Action to log the user out by removing the token from local storage
     * and resetting the state.
     *
     * @param {AuthState} state - The current state of the authentication slice.
     */
    logout: state => {
      localStorage.removeItem('userToken'); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    /**
     * Handles the pending state for the loginUser async action.
     *
     * @param {AuthState} state - The current state of the authentication slice.
     */
    [loginUser.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    /**
     * Handles the fulfilled state for the loginUser async action.
     * Updates the userInfo and token upon successful login.
     *
     * @param {AuthState} state - The current state of the authentication slice.
     * @param {Object} payload - The response payload from the loginUser action.
     */
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.success = true; // login successful
      state.userToken = payload.token;
    },
    /**
     * Handles the rejected state for the loginUser async action.
     * Updates the error state if the login fails.
     *
     * @param {AuthState} state - The current state of the authentication slice.
     * @param {string} payload - The error message received during login failure.
     */
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

/**
 * Function to check if the user's JWT token has expired. If the token has expired,
 * the user is logged out by dispatching the `logout` action.
 *
 * @function
 * @returns {Function} A Redux thunk function that checks token expiration and logs the user out if expired.
 *
 * @example
 * dispatch(checkTokenExpiration());
 */
export const checkTokenExpiration = () => dispatch => {
  const token = localStorage.getItem('userToken');

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    console.debug('in check exp');

    if (decodedToken.exp < currentTime) {
      // token has expired, log the user out
      dispatch(logout());
    }
  }
};

/**
 * @module pages/AdminPage
 *
 * @description This module is responsible for managing the admin interface, allowing admins to view commits, set golden commits, and trigger rules updates.
 */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Input, Space, notification } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, DatePicker, Select } from 'antd';
import { useSelector } from 'react-redux';
import {
  useGetBranchCommitsQuery,
  useGetGoldenCommitQuery,
  useGetBranchesCommitsQuery,
} from '../services/api';
import {
  useSetGoldenMutation,
  useUpdateRulesMutation,
} from '../services/admin';
import {
  createOptionsArray,
  createFiltersArray,
  arrayFromObjects,
  splitName,
  truncateString,
} from '../utils/util';
import { ConsoleSqlOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { ExclamationCircleFilled, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

/**
 * AdminPage Component
 * @returns {JSX.Element} The AdminPage component for managing commits and triggering updates.
 */
const AdminPage = () => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);
  const { userInfo } = useSelector(state => state.auth);
  const navigate = useNavigate();

  /**
   * Handles selection of a commit.
   * @param {string} key - The commit SHA key.
   */
  const handleSelection = key => {
    setSelectedKey(key);
    setSelectedValue(key); // Get the selected value from the data source
  };
  if (userInfo?.role != 'Admin') {
    navigate('/');
  }
  const [branch, setBranch] = useState('master');
  const [checkoutBranch, setCheckoutBranch] = useState('master');

  const { data: dataSource = [] } = useGetBranchCommitsQuery({
    branchName: branch,
  });

  const { data: goldenCommit = {} } = useGetGoldenCommitQuery();

  const [setGolden] = useSetGoldenMutation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  /**
   * Filters the commits table based on the search input.
   * @param {Array} selectedKeys - Selected keys for the filter.
   * @param {Function} confirm - Function to confirm the filter.
   * @param {string} dataIndex - The column being filtered.
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**
   * Resets the search filter.
   * @param {Function} clearFilters - Function to clear the filters.
   */
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  /**
   * Filters and displays commits based on the search input.
   * @param {string} dataIndex - The column being filtered.
   * @returns {Object} Filter settings.
   */
  const searchFilter = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
          marginRight: '10px',
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const [filteredDateRange, setFilteredDateRange] = useState(null);

  const [dateRangeFilter, setDateRangeFilter] = useState([]);

  const handleDateRangeFilterChange = dates => {
    setDateRangeFilter(dates);
  };

  const clearDateRangeFilter = () => {
    setDateRangeFilter([]);
  };
  const columns = [
    {
      title: 'Select',
      dataIndex: 'key', // Use a unique key for each row
      width: '10%',
      render: (text, record) => {
        if (record?.commit != goldenCommit?.commit_sha) {
          return (
            <input
              type='radio'
              name='selectedItem'
              value={record.commit}
              checked={record.commit === selectedKey}
              onChange={() => handleSelection(record.commit)}
            />
          );
        }
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 'auto',
      filterIcon: <CalendarOutlined style={{ marginRight: '10px' }} />,
      filteredValue: dateRangeFilter,
      onFilter: (value, record) => {
        if (!dateRangeFilter || dateRangeFilter.length === 0) return true;
        const [startDate, endDate] = dateRangeFilter;
        const dateObject = moment(record.date).toDate();

        return dateObject >= startDate && dateObject <= endDate;
      },
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <RangePicker onChange={handleDateRangeFilterChange} />
          <Button onClick={clearDateRangeFilter} style={{ marginTop: 8 }} block>
            Clear
          </Button>
        </div>
      ),
    },
    {
      title: 'Commits',
      dataIndex: 'commit',
      key: 'commit',
      filterMode: 'tree',
      filterSearch: true,
      ...searchFilter('item'),
      onFilter: (value, record) => record?.commit.startsWith(value),
      filters: dataSource
        ? createFiltersArray(arrayFromObjects(dataSource, 'commit'))
        : [],
      width: 'auto',
      render: (text, record) => {
        if (record?.commit == goldenCommit?.commit_sha) {
          return (
            <span style={{ color: 'orange' }}>
              {truncateString(record?.commit, 6)}
            </span>
          );
        } else {
          return <span>{truncateString(record?.commit, 6)}</span>;
        }
      },
    },
    {
      title: 'Report URL',
      dataIndex: 'url',
      key: 'url',
      width: 'auto',
      render: (text, record) => (
        <a
          style={{
            fontSize: '14px',
            marginRight: '50px',
            alignItems: 'center',
            display: 'inline-flex',
            minHeight: '50px',
          }}
          href={record?.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          {record?.build_id}
        </a>
      ),
    },
  ];

  const [error, setError] = useState('');

  /**
   * Triggers a commit comparison between the golden commit and the selected commit.
   */
  const handleCompare = () => {
    if (!goldenCommit) {
      notification.error({
        message: 'Error',
        description: 'No golden commit set to compare against',
      });

      // Automatically close the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        notification.destroy(); // Close the notification
      }, 3000);
    }
    console.debug('Selected Value:', selectedValue);
    if (goldenCommit !== {}) {
      const link = `/compare?sourceAType=Commit&sourceBType=Commit&sourceAName=${goldenCommit.commit_sha}&sourceBName=${selectedValue}`;
      navigate(link);
    }
  };

  /**
   * Handles setting a commit as the golden commit.
   */
  const handleSetGolden = async () => {
    console.debug('Selected Value:', selectedValue);

    try {
      // Perform your mutation here using yourMutationFunction
      const response = await setGolden({
        commitSHA: selectedValue,
        overwrite: false,
      });
      console.debug('response is', response);
      if (response?.data?.error) {
        setError(response?.data?.error);
        throw new Error('Error: Faulty Commit');
      }
      notification.info({
        message: 'Success',
        description: 'Commit was set to golden.',
      });

      setTimeout(() => {
        // Reload the page after one minute
        window.location.reload();
      }, 5000);
    } catch (error) {
      // Handle errors here
      notification.error({
        message: 'ERROR',
        description:
          'There was an error setting the commit to golden. It might be a faulty commit',
      });

      // Automatically close the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        notification.destroy(); // Close the notification
      }, 3000);
    }
  };

  const handleOverwrite = async () => {
    try {
      // Perform your mutation here using yourMutationFunction
      const response = await setGolden({
        commitSHA: selectedValue,
        overwrite: true,
      });
      console.debug('response is', response);
      if (response?.data?.error) {
        setError(response?.data?.error);
        throw new Error('Error: Faulty Commit');
      }
      notification.info({
        message: 'Success',
        description: 'Commit was set to golden.',
      });

      setTimeout(() => {
        // Reload the page after one minute
        window.location.reload();
      }, 5000);
    } catch (error) {
      // Handle errors here
      notification.error({
        message: 'ERROR',
        description: 'There was an error setting the commit to golden.',
      });

      // Automatically close the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        notification.destroy(); // Close the notification
      }, 3000);
    }
  };

  useEffect(() => {
    // Sort the array, pinning objects based on the condition
    const temp = [...dataSource];
    const indexOfItemToMove = temp.findIndex(
      item => item.commit === goldenCommit?.commit_sha,
    );

    // Check if the item was found in the array
    if (indexOfItemToMove !== -1) {
      // Remove the item from its current position
      const movedItem = temp.splice(indexOfItemToMove, 1)[0];

      // Add the item to the beginning of the array
      temp.unshift(movedItem);
    }
    setData(temp);
  }, [dataSource, goldenCommit]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const warning = () => {
    Modal.confirm({
      title: 'Overwrite Notice',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p>Are you sure you want to overwrite?</p>
          <p> {error}</p>
        </div>
      ),
      onOk() {
        handleOverwrite();
      },
      onCancel() {
        handleCancel();
      },
      okText: 'Yes',
    });
  };

  const { data: branchesCommits } = useGetBranchesCommitsQuery();
  const [selectOptionsOne, setSelectOptionsOne] = useState([]);

  useEffect(() => {
    let newOptions = [];
    setSelectOptionsOne(newOptions);
    if (branchesCommits) {
      newOptions = createOptionsArray(branchesCommits['branches']);
      const index = newOptions.findIndex(option => option.value === 'master');
      if (index !== -1) {
        const removedItem = newOptions.splice(index, 1)[0];
        newOptions.unshift(removedItem);
      }
    }
    setSelectOptionsOne(newOptions);
  }, [branchesCommits]);

  const handleBranchChange = value => {
    const newValue = splitName(value)?.first ? splitName(value)?.first : value;
    let lastValue = splitName(value)?.last ? splitName(value)?.last : value;
    lastValue = lastValue.replace(/[{()}]/g, '');
    setBranch(newValue);
    setCheckoutBranch(lastValue);
  };

  const [updateRules] = useUpdateRulesMutation();

  const handleUpdateRules = async () => {
    console.debug('Selected Value:', selectedValue);
    try {
      // Perform your mutation here using yourMutationFunction
      let response;
      if (branch === 'master') {
        response = await setGolden({
          commitSHA: selectedValue,
          overwrite: false,
        });
      } else {
        response = await updateRules({
          branch: checkoutBranch,
          commitSHA: selectedValue,
          overwrite: false,
        });
      }

      if (response?.data?.error) {
        setError(response?.data?.error);
        throw new Error('Error: Faulty PR');
      }
      notification.info({
        message: 'Success',
        description: 'Triggered job to update rules.',
      });

      if (branch === 'master') {
        setTimeout(() => {
          // Reload the page after one minute
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      // Handle errors here
      notification.error({
        message: 'ERROR',
        description:
          'There was an error updating rules. It might be a faulty PR',
      });

      // Automatically close the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        notification.destroy(); // Close the notification
      }, 3000);
    }
  };

  const handleForceUpdateRules = async () => {
    try {
      // Perform your mutation here using yourMutationFunction
      let response;
      if (branch === 'master') {
        response = await setGolden({
          commitSHA: selectedValue,
          overwrite: true,
        });
      } else {
        response = await updateRules({
          branch: checkoutBranch,
          commitSHA: selectedValue,
          overwrite: true,
        });
      }

      if (response?.data?.error) {
        setError(response?.data?.error);
        throw new Error('Error: Faulty PR');
      }
      notification.info({
        message: 'Success',
        description: 'Triggered job to update rules.',
      });

      if (branch === 'master') {
        setTimeout(() => {
          // Reload the page after one minute
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      // Handle errors here
      notification.error({
        message: 'ERROR',
        description:
          'There was an error updating rules. It might be a faulty PR',
      });

      // Automatically close the notification after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        notification.destroy(); // Close the notification
      }, 3000);
    }
  };

  return (
    <div>
      <span
        style={{
          marginRight: '10px',
          marginLeft: '5px',
          fontWeight: 'bold',
        }}
      >
        Branch:
      </span>
      <Select
        style={{
          marginBottom: '20px',
          width: '20%',
        }}
        className='sourceA-select'
        showSearch
        // allowClear
        onChange={handleBranchChange}
        options={selectOptionsOne}
        disabled={!branchesCommits}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={'master'}
      />
      <Table
        dataSource={data}
        columns={columns}
        pagination={true}
        bordered
        scroll={{
          x: '60vw',
          y: '35vw',
        }}
        sticky
        loading={data?.length === 0}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Button
          type='primary'
          onClick={handleCompare}
          disabled={!selectedValue}
        >
          Compare
        </Button>
        <Button
          type='primary'
          onClick={handleUpdateRules}
          disabled={!selectedValue}
        >
          Update Rules
        </Button>
        <Button
          type='primary'
          onClick={handleForceUpdateRules}
          disabled={!selectedValue}
        >
          Force Update Rules
        </Button>
        {/* <Button
          type="primary"
          onClick={handleSetGolden}
          disabled={!selectedValue || branch !== "master"}
        >
          Set Golden
        </Button>
        <Button
          type="primary"
          onClick={warning}
          disabled={!selectedValue || branch !== "master"}
        >
          Overwrite Set Golden
        </Button> */}
      </div>
    </div>
  );
};

export default AdminPage;

/**
 * @module pages/ComparisonPage
 *
 * @description
 * A React component that provides a comparison view between two sources of data. The component allows users to select various metrics, platforms, designs, and other filters to compare data across different branches or commits. It displays the comparison results in tables and charts and allows for export functionality.
 */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, Space, Table, Spin, Tabs, TabPane } from 'antd';
import { Select, Collapse } from 'antd';
import {
  createOptionsArray,
  createFiltersArray,
  roundToFirstNonZero,
  splitName,
} from '../utils/util';
import {
  useGetBranchesCommitsQuery,
  useGetBranchCommitPlatformsQuery,
  useGetCompareQuery,
  useGetSourceDataQuery,
  useGetPlatformsSummaryQuery,
  useGetBuildNumbersQuery,
} from '../services/api';
import CustomSelect from '../components/MetricTrends/CustomSelect';
import { RetweetOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import SummaryTable from '../components/Comparison/SummaryTable';
import './ComparisonPage.css';
import PlatformsSummaryTable from '../components/Comparison/PlatformsSummaryTable';
import PlatformsAverageTable from '../components/Comparison/PlatformsAverageTable';
import ExportButton from '../components/Comparison/ExportButton';

const { Column, ColumnGroup } = Table;
const { Panel } = Collapse;

/**
 * ComparisonTable component
 *
 * This component allows users to compare data from two different sources based on various filters and metrics. It supports branch and commit comparisons, metric selections, and platform designs. The component dynamically updates based on user interactions and displays comparison results in tables.
 *
 * @component
 * @example
 * return (
 *   <ComparisonTable />
 * );
 */
const ComparisonTable = () => {
  const variant = 'base';
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const newSearchParams = new URLSearchParams();
  const [newSearchParams, setSearchParams] = useSearchParams();

  const intialsourceAType = searchParams.get('sourceAType') || 'Branch';
  const intialsourceBType = searchParams.get('sourceBType') || 'Branch';

  const [refOneType, setRefOneType] = useState(intialsourceAType);
  const [refTwoType, setRefTwoType] = useState(intialsourceBType);

  const intialsourceAName = searchParams.get('sourceAName') || '';
  const intialsourceBName = searchParams.get('sourceBName') || '';

  const [value1, setValue1] = useState(
    splitName(intialsourceAName)?.first
      ? splitName(intialsourceAName)?.first
      : intialsourceAName,
  );
  const [value2, setValue2] = useState(
    splitName(intialsourceBName)?.first
      ? splitName(intialsourceBName)?.first
      : intialsourceBName,
  );

  const intialsourceAID = searchParams.get('sourceAID') || '';
  const intialsourceBID = searchParams.get('sourceBID') || '';

  const [buildId1, setBuildId1] = useState(intialsourceAID);
  const [buildId2, setBuildId2] = useState(intialsourceBID);

  const [buildId1Loading, setBuildId1Loading] = useState(false);
  const [buildId2Loading, setBuildId2Loading] = useState(intialsourceBID);

  const intialMetrics = searchParams.get('metrics')?.split(',') || [];

  const [selectedMetrics, setSelectedMetrics] = useState(intialMetrics);

  const intialPlatform = searchParams.get('platform') || '';

  const [selectedPlatform, setSelectedPlatform] = useState(intialPlatform);

  const intialDesigns = searchParams.get('designs')?.split(',') || [];

  const [selectedDesigns, setSelectedDesigns] = useState(intialDesigns);

  const intialPRFilter = searchParams.get('prFilter') || 'off';
  const [prFilterClicked, setprFilterClicked] = useState(
    intialPRFilter == 'on' ? true : false,
  );

  const [selectOptionsOne, setSelectOptionsOne] = useState([]);
  const [selectOptionsTwo, setSelectOptionsTwo] = useState([]);
  const [platformUnion, setPlatformUnion] = useState({});
  const [platformsDesigns, setPlatformsDesigns] = useState({});
  const [filtersList, setFilterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [platformsSummaryTableData, setPlatformsSummaryTableData] = useState(
    [],
  );
  const [platformsMetricAvgData, setPlatformsMetricAvgData] = useState([]);

  const [metricsList, setMetricsList] = useState([]);

  /**
   * Handles filter changes based on selected value and record.
   *
   * @param {number} value - The selected filter value index.
   * @param {object} record - The record to be filtered.
   * @returns {boolean} - Returns true if the record matches the filter, otherwise false.
   */
  const handleFilterChange = (value, record) => {
    console.debug('filter record is', record);
    console.debug('filter value index is', value);
    console.debug('filter value is', compareData.metrics[value]);
    console.debug(
      'filter result is',
      compareData.metrics[value] === record.metrics,
    );
    return compareData.metrics[value] === record.metrics;
  };

  /**
   * Handles changes to the selected platform and updates the URL search parameters.
   *
   * @param {string} value - The selected platform value.
   */
  const handleChange = value => {
    setSelectedPlatform(value);
    setClearPlatform(false);
    searchParams.set('platform', value);
    navigate(`?${searchParams.toString()}`);
  };

  const { data: platforms1Obj = {} } = useGetBranchCommitPlatformsQuery(
    refOneType === 'Branch'
      ? { branchName: value1, commitSHA: '' }
      : { branchName: '', commitSHA: value1 },
  );
  const { data: platforms2Obj = {} } = useGetBranchCommitPlatformsQuery(
    refTwoType === 'Branch'
      ? { branchName: value2, commitSHA: '' }
      : { branchName: '', commitSHA: value2 },
  );

  const { data: buildNumbers1 = [], loading: buildNumbers1Loading } =
    useGetBuildNumbersQuery(
      refOneType === 'Branch'
        ? { branchName: value1, commitSHA: '' }
        : { branchName: '', commitSHA: value1 },
    );
  const { data: buildNumbers2 = [], loading: buildNumbers2Loading } =
    useGetBuildNumbersQuery(
      refTwoType === 'Branch'
        ? { branchName: value2, commitSHA: '' }
        : { branchName: '', commitSHA: value2 },
    );

  /**
   * Handles changes to the selected designs and updates the URL search parameters.
   *
   * @param {string[]} value - The selected designs.
   */
  const handleDesignChange = value => {
    const joined = value.length > 1 ? value.join(',') : value;
    setSelectedDesigns(value);
    setLoading(compareData?.compare == {});
    setClearDesigns(false);
    if (value.length > 0) {
      searchParams.set('designs', value);
    } else {
      searchParams.delete('designs');
    }
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the selected metrics and updates the URL search parameters.
   *
   * @param {string[]} value - The selected metrics.
   */
  const handleMetricChange = value => {
    const joined = value.length > 1 ? value.join(',') : value;
    setSelectedMetrics(value);
    setLoading(Object.keys(platformsSummaryTableData).length !== 0);
    setClearMetrics(false);
    if (joined.length > 0) {
      searchParams.set('metrics', joined);
    } else {
      searchParams.delete('metrics');
    }
    navigate(`?${searchParams.toString()}`);
  };

  const { data: branchesCommits } = useGetBranchesCommitsQuery();

  /**
   * Handles changes to the source A value and updates the URL search parameters.
   *
   * @param {string} value - The selected value for source A.
   */
  const handleValue1Change = value => {
    const newValue = splitName(value)?.first ? splitName(value)?.first : value;
    setValue1(newValue);
    searchParams.set('sourceAName', value);
    // load time for build id
    setBuildId1Loading(true);
    setBuildId1();
    searchParams.delete('sourceAID');

    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the source B value and updates the URL search parameters.
   *
   * @param {string} value - The selected value for source B.
   */
  const handleValue2Change = value => {
    const newValue = splitName(value)?.first ? splitName(value)?.first : value;
    setValue2(newValue);
    searchParams.set('sourceBName', value);
    navigate(`?${searchParams.toString()}`);
    // load time for build id
    setBuildId2Loading(true);
    setBuildId2();
    searchParams.delete('sourceBID');

    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the build ID for source A and updates the URL search parameters.
   *
   * @param {string} value - The selected build ID for source A.
   */
  const handleBuildID1Change = value => {
    setBuildId1(value);
    searchParams.set('sourceAID', value);
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the build ID for source B and updates the URL search parameters.
   *
   * @param {string} value - The selected build ID for source B.
   */
  const handleBuildID2Change = value => {
    setBuildId2(value);
    searchParams.set('sourceBID', value);
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the reference type for source A and updates the URL search parameters.
   *
   * @param {string} value - The selected reference type for source A.
   */
  const handleChangeOne = value => {
    setRefOneType(value);
    searchParams.set('sourceAType', value);

    setValue1();
    handleSourceAClear();
    searchParams.delete('sourceAName');

    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles changes to the reference type for source B and updates the URL search parameters.
   *
   * @param {string} value - The selected reference type for source B.
   */
  const handleChangeTwo = value => {
    setRefTwoType(value);
    searchParams.set('sourceBType', value);

    setValue2();
    handleSourceBClear();
    searchParams.delete('sourceBName');

    navigate(`?${searchParams.toString()}`);
  };

  useEffect(() => {
    searchParams.set('sourceAType', intialsourceAType);
    searchParams.set('sourceBType', intialsourceBType);
    navigate(`?${searchParams.toString()}`);
  }, []);

  const {
    data: compareData = {},
    isLoading: compLoading,
    isError: compError,
  } = useGetCompareQuery({
    sourceA: {
      name: value1,
      type: refOneType,
      buildId: buildId1,
    },
    sourceB: {
      name: value2,
      type: refTwoType,
      buildId: buildId2,
    },
    platform: selectedPlatform,
    designs: selectedDesigns,
    variant: variant,
  });

  const {
    data: platformsSummary = {},
    isLoading,
    isError,
  } = useGetPlatformsSummaryQuery({
    platforms_designs: platformsDesigns,
    sourceA: {
      name: value1,
      type: refOneType,
      buildId: buildId1,
    },
    sourceB: {
      name: value2,
      type: refTwoType,
      buildId: buildId2,
    },
    variant: 'base',
  });

  const { data: value1Data = {} } = useGetSourceDataQuery(
    refOneType === 'Branch'
      ? { branchName: value1, commitSHA: '' }
      : { branchName: '', commitSHA: value1 },
  );

  const { data: value2Data = {} } = useGetSourceDataQuery(
    refTwoType === 'Branch'
      ? { branchName: value2, commitSHA: '' }
      : { branchName: '', commitSHA: value2 },
  );

  /**
   * useEffect hook to update the table data and metrics average when platformsSummary or selectedMetrics change.
   *
   * This hook processes the platformsSummary data to compute table data and metrics averages. It transforms the summary data into a format suitable for rendering in a table and calculates average metrics for display.
   */
  useEffect(() => {
    if (Object.keys(platformsSummary).length !== 0) {
      const columnNames = Object.keys(platformsSummary).filter(
        item => item !== 'metrics',
      );

      const rowNames = Object.keys(platformsSummary[columnNames[0]]).filter(
        item => item !== 'avg',
      );

      const tableData = rowNames.map(rowName => {
        let name;
        switch (rowName) {
          case 'I':
            name = 'Improvement';
            break;
          case 'D':
            name = 'Degradation';
            break;
          case 'N':
            name = 'No Change';
            break;
          case 'F':
            name = 'Failed Metrics';
            break;
        }
        const rowData = { status: name };
        columnNames.forEach(columnName => {
          rowData[columnName] = 0;
          if (selectedMetrics.length !== 0) {
            selectedMetrics.forEach(
              metric =>
                (rowData[columnName] =
                  rowData[columnName] +
                  platformsSummary[columnName][rowName][metric]),
            );
          } else {
            rowData[columnName] = platformsSummary[columnName][rowName]['all'];
          }
        });
        return rowData;
      });

      const mapMetrics = {};
      columnNames.forEach(columnName => {
        const designsCount = platformsSummary[columnName]['avg']['designs'];
        let metrics;
        if (selectedMetrics.length === 0) {
          metrics = Object.keys(platformsSummary[columnName]['avg']);
        } else {
          metrics = selectedMetrics;
        }
        metrics.forEach(metric => {
          if (metric === 'designs') {
            return;
          }
          if (!mapMetrics[metric]) {
            mapMetrics[metric] = {};
            mapMetrics[metric]['summary'] = 0;
          }
          const value = platformsSummary[columnName]['avg'][metric];
          mapMetrics[metric][columnName] = value;
          mapMetrics[metric]['metrics'] = metric;
          mapMetrics[metric]['summary'] =
            mapMetrics[metric]['summary'] + value / designsCount;
        });
      });
      console.log('table data', tableData);
      setPlatformsSummaryTableData(tableData);
      setPlatformsMetricAvgData(Object.values(mapMetrics));
    }
  }, [platformsSummary, selectedMetrics]);

  /**
   * useEffect hook to update buildId1 when buildNumbers1 change.
   *
   * This hook sets the build ID for source A based on the available build numbers. It initializes the build ID when build numbers are available and updates the loading state accordingly.
   */
  useEffect(() => {
    if (buildId1Loading && buildNumbers1.length !== 0) {
      setBuildId1Loading(false);
      handleBuildID1Change(buildNumbers1[0]);
    }
  }, [buildNumbers1]);

  /**
   * useEffect hook to update buildId2 when buildNumbers2 change.
   *
   * This hook sets the build ID for source B based on the available build numbers. It initializes the build ID when build numbers are available and updates the loading state accordingly.
   */
  useEffect(() => {
    if (buildId2Loading && buildNumbers2.length !== 0) {
      setBuildId2Loading(false);
      handleBuildID2Change(buildNumbers2[0]);
    }
  }, [buildNumbers2]);

  /**
   * useEffect hook to update the options for the first reference type based on branches and commits.
   *
   * This hook generates options for the first reference type selector based on available branches or commits. It adjusts the options array based on the selected reference type and sets it in the component state.
   */
  useEffect(() => {
    let newOptions = [];
    setSelectOptionsOne(newOptions);
    if (branchesCommits) {
      if (refOneType === 'Branch') {
        newOptions = createOptionsArray(branchesCommits['branches']);
        const index = newOptions.findIndex(option => option.value === 'master');
        if (index !== -1) {
          const removedItem = newOptions.splice(index, 1)[0];
          newOptions.unshift(removedItem);
        }
      } else {
        newOptions = createOptionsArray(branchesCommits['commits']);
      }
    }
    setSelectOptionsOne(newOptions);
  }, [refOneType, branchesCommits]);

  /**
   * useEffect hook to update the options for the second reference type based on branches and commits.
   *
   * This hook generates options for the second reference type selector based on available branches or commits. It adjusts the options array based on the selected reference type and sets it in the component state.
   */
  useEffect(() => {
    let newOptions = [];
    setSelectOptionsTwo(newOptions);
    if (branchesCommits) {
      if (refTwoType === 'Branch') {
        newOptions = createOptionsArray(branchesCommits['branches']);
        const index = newOptions.findIndex(option => option.value === 'master');
        if (index !== -1) {
          const removedItem = newOptions.splice(index, 1)[0];
          newOptions.unshift(removedItem);
        }
      } else {
        newOptions = createOptionsArray(branchesCommits['commits']);
      }
    }
    setSelectOptionsTwo(newOptions);
  }, [refTwoType, branchesCommits]);

  /**
   * useEffect hook to update the platform union and transformed data when platforms1Obj or platforms2Obj change.
   *
   * This hook merges platform data from two sources and transforms it into a structure suitable for rendering. It updates the component state with the union of platforms and the transformed design data.
   */
  useEffect(() => {
    const union = Object.assign({}, platforms1Obj, platforms2Obj);
    setPlatformUnion(union);

    const transformedData = Object.entries(union).reduce(
      (result, [parentKey, value]) => {
        const childKeys = Object.keys(value).map(subKey => `${subKey}`);
        return { ...result, [parentKey]: childKeys };
      },
      {},
    );
    setPlatformsDesigns(transformedData);
  }, [platforms1Obj, platforms2Obj]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  /**
   * Handles the search functionality for table columns.
   *
   * @param {string[]} selectedKeys - The keys of the selected filters.
   * @param {function} confirm - The function to confirm the filter.
   * @param {string} dataIndex - The data index of the column to be searched.
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  /**
   * Resets the search filters and clears the search text.
   *
   * @param {function} clearFilters - The function to clear filters.
   */
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  /**
   * Gets the search properties for a table column.
   *
   * @param {string} dataIndex - The data index of the column to be searched.
   * @returns {object} - The properties for the search functionality of the column.
   */
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  console.debug('compare data', compareData);

  const [selectedFilters, setSelectedFilters] = useState(
    intialPRFilter == 'on'
      ? [
          'detailedroute__route__wirelength',
          'cts__design__instance__count__hold_buffer',
          'finish__design__instance__area',
          'finish__timing__drv__max_slew',
          'finish__power__total',
          'floorplan__power__internal__total',
          'floorplan__power__switching__total',
          'finish__timing__setup__ws',
          'finish__timing__setup__tns',
        ]
      : [],
  );
  const [filterType, setFilterType] = useState(
    intialPRFilter == 'on' ? 'metrics' : '',
  );

  /**
   * Handles the click event to set PR filter and update the URL search parameters.
   */
  const handleClick = () => {
    setprFilterClicked(true);
    setSelectedFilters([
      'detailedroute__route__wirelength',
      'cts__design__instance__count__hold_buffer',
      'finish__design__instance__area',
      'finish__timing__drv__max_slew',
      'finish__power__total',
      'floorplan__power__internal__total',
      'floorplan__power__switching__total',
      'finish__timing__setup__ws',
      'finish__timing__setup__tns',
    ]);
    setFilterType('metrics');
    searchParams.set('prFilter', 'on');
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles clearing of PR filters and updates the URL search parameters.
   */
  const handleClearFilters = () => {
    setprFilterClicked(false);
    setSelectedFilters([]);
    setFilterType('');
    searchParams.set('prFilter', 'off');
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles selection of child data and updates the filters based on record status and design.
   *
   * @param {object} record - The record data.
   * @param {string} design - The design type.
   */
  const handleChildData = (record, design) => {
    let status = 'no_change';
    switch (record?.status) {
      case 'Improvement':
        status = 'improvements';
        break;
      case 'Degradation':
        status = 'degradations';
        break;
      case 'No Change':
        status = 'no_change';
        break;
      case 'Failed Metrics':
        status = 'failed_metrics';
        break;
    }
    setSelectedFilters([status]);
    setFilterType('status' + '/' + design);
  };

  /**
   * Sorter function for sorting options by label, with "master" appearing first.
   *
   * @param {object} a - The first option to compare.
   * @param {object} b - The second option to compare.
   * @returns {number} - The sorting order.
   */
  const sorter = (a, b) => {
    if (a.value === 'master') {
      return -1;
    }
    return a.label.localeCompare(b.label);
  };

  const [clearMetrics, setClearMetrics] = useState(false);

  const [clearDesigns, setClearDesigns] = useState(false);

  const [clearPlatform, setClearPlatform] = useState(false);

  /**
   * Handles clearing of platform and resets the design selection.
   */
  const handlePlatformClear = () => {
    setClearDesigns(true);
    setSelectedDesigns([]);
    searchParams.delete('designs');
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles clearing of source A and resets related states.
   */
  const handleSourceAClear = () => {
    setClearDesigns(true);
    setSelectedDesigns([]);
    setClearMetrics(true);
    setSelectedMetrics([]);
    setClearPlatform(true);
    setSelectedPlatform();
    setBuildId1();
    searchParams.delete('sourceAName');
    searchParams.delete('sourceAID');
    searchParams.delete('metrics');
    searchParams.delete('platform');
    searchParams.delete('designs');
    searchParams.delete('prFilter');
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles clearing of source B and resets related states.
   */
  const handleSourceBClear = () => {
    setClearDesigns(true);
    setSelectedDesigns([]);
    setClearMetrics(true);
    setSelectedMetrics([]);
    setClearPlatform(true);
    setSelectedPlatform();
    setBuildId2();
    searchParams.delete('sourceAName');
    searchParams.delete('sourceAID');
    searchParams.delete('metrics');
    searchParams.delete('platform');
    searchParams.delete('designs');
    searchParams.delete('prFilter');
    navigate(`?${searchParams.toString()}`);
  };

  /**
   * Handles clearing of metrics and resets the selection.
   */
  const handleMetricClear = () => {
    setClearMetrics(true);
    setSelectedMetrics([]);
    searchParams.delete('metrics');
    navigate(`?${searchParams.toString()}`);
  };

  console.debug('compare data', compareData);
  console.debug('platforms summary data', platformsSummary);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      {/**
       * A fieldset component for selecting sources to compare.
       *
       * This component allows users to choose and compare two sources by selecting build IDs and reference types. It includes options for both base and test sources with associated build IDs and URLs for reports.
       */}
      <fieldset
        style={{
          border: '1px solid #000',
          borderColor: '#D3D3D3',
          marginBottom: '20px',
        }}
      >
        <legend style={{ color: '#A9A9A9' }}>Choose Sources To Compare</legend>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '10px',
            paddingLeft: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '35%',
              alignItems: 'flex-start',
              marginRight: '10px',
            }}
          >
            <label style={{ fontSize: '14px', paddingTop: '20px' }}>
              Base:
            </label>
            <label style={{ fontSize: '14px', paddingTop: '20px' }}>
              Base Build ID:
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
              marginRight: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              <Select
                defaultValue={refOneType}
                // placeholder="Branch"
                style={{
                  width: '30%',
                  marginRight: '10px',
                }}
                allowClear
                onChange={handleChangeOne}
                options={createOptionsArray(['Branch', 'Commit'])}
              />
              <Select
                style={{
                  width: '100%',
                }}
                className='sourceA-select'
                showSearch
                allowClear
                onChange={handleValue1Change}
                options={selectOptionsOne}
                disabled={!branchesCommits}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={intialsourceAName}
                onClear={() => handleSourceAClear()}
                value={value1}
              />
            </div>
            <Select
              loading={buildId1Loading}
              style={{
                width: '100%',
              }}
              showSearch
              allowClear
              onChange={handleBuildID1Change}
              defaultValue={
                intialsourceAID !== '' ? intialsourceAID : buildNumbers1[0]
              }
              options={
                buildNumbers1.length !== 0
                  ? createOptionsArray(buildNumbers1)
                  : []
              }
              disabled={buildNumbers1.length === 0 || buildId1Loading}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={buildId1}
            />
          </div>

          <span
            style={{
              fontSize: '16px',
              textAlign: 'center',
              marginRight: '20px',
            }}
          >
            VS
          </span>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '35%',
              alignItems: 'flex-start',
              marginRight: '10px',
            }}
          >
            <label style={{ fontSize: '14px', paddingTop: '20px' }}>
              Test:
            </label>
            <label style={{ fontSize: '14px', paddingTop: '20px' }}>
              Test Build ID:
            </label>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                marginBottom: '12px',
                marginTop: '12px',
              }}
            >
              <Select
                defaultValue={refTwoType}
                style={{
                  width: '30%',
                  marginRight: '10px',
                }}
                allowClear
                onChange={handleChangeTwo}
                options={createOptionsArray(['Branch', 'Commit'])}
              />
              <Select
                style={{
                  width: '100%',
                }}
                showSearch
                allowClear
                onChange={handleValue2Change}
                options={selectOptionsTwo}
                disabled={!branchesCommits}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={intialsourceBName}
                onClear={() => handleSourceBClear()}
                value={value2}
              />
            </div>
            <Select
              loading={buildId2Loading}
              style={{
                width: '100%',
              }}
              showSearch
              allowClear
              onChange={handleBuildID2Change}
              defaultValue={
                intialsourceBID !== '' ? intialsourceBID : buildNumbers2[0]
              }
              options={
                buildNumbers2.length !== 0
                  ? createOptionsArray(buildNumbers2)
                  : []
              }
              disabled={buildNumbers2.length === 0 || buildId2Loading}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={buildId2}
            />
          </div>
        </div>

        {(value1Data?.jenkins_url || value2Data?.jenkins_url) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'center',
              marginBottom: '12px',
              marginTop: '12px',
            }}
          >
            {value1Data?.jenkins_url && (
              <a
                style={{ fontSize: '14px', marginRight: '50px' }}
                href={value1Data?.jenkins_url}
                target='_blank'
                rel='noopener noreferrer'
              >
                Base Report URL
              </a>
            )}
            {value2Data?.jenkins_url && (
              <a
                style={{ fontSize: '14px' }}
                href={value2Data?.jenkins_url}
                target='_blank'
                rel='noopener noreferrer'
              >
                Test Report URL
              </a>
            )}
          </div>
        )}
      </fieldset>

      {/**
       * Conditional rendering block for displaying platform summary tables.
       * - If `value1` and `value2` are truthy and `platformsSummaryTableData` is not empty:
       *   - A `div` is rendered containing:
       *     - A `Collapse` component with a default active key.
       *     - A `Panel` within the `Collapse` with the header "Platforms Summary Tables".
       *       - Contains a `CustomSelect` component for selecting metrics.
       *       - Displays two tables: `PlatformsSummaryTable` and `PlatformsAverageTable`.
       * - If the conditions are not met:
       *   - Displays a loading spinner (`Spin` component) when `value1` and `value2` are truthy.
       */}
      {value1 && value2 && platformsSummaryTableData.length !== 0 ? (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              marginBottom: '20px',
            }}
          >
            <Collapse defaultActiveKey={['1']}>
              <Panel header={'Platforms Summary Tables'} key='1'>
                <div style={{ width: '30%' }}>
                  <CustomSelect
                    style={{
                      width: '100%',
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                    placeholder='Please select metric(s)'
                    onSelect={handleMetricChange}
                    options={
                      Object.keys(platformsSummary).length !== 0
                        ? createOptionsArray(
                            platformsSummary['metrics'].slice().sort((a, b) => {
                              return a.localeCompare(b, undefined, {
                                sensitivity: 'base',
                              });
                            }),
                          )
                        : []
                    }
                    defaultSelection={intialMetrics}
                    clear={clearMetrics}
                    onClear={() => handleMetricClear()}
                  />
                </div>
                <h4>Platforms Count Table:</h4>
                <PlatformsSummaryTable
                  data={platformsSummaryTableData}
                  platformUnion={
                    platformUnion !== {} ? Object.keys(platformUnion) : []
                  }
                  variant={variant}
                  loading={Object.keys(platformsSummary).length === 0}
                  title='Platforms Summary Table'
                />
                <h4>Platforms Average Table:</h4>
                <PlatformsAverageTable
                  data={platformsMetricAvgData}
                  platformUnion={
                    platformUnion !== {} ? Object.keys(platformUnion) : []
                  }
                  variant={variant}
                  loading={Object.keys(platformsSummary).length === 0}
                  title='Platforms Average Table'
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      ) : (
        value1 && value2 && <Spin size='large' />
      )}

      {/**
       * Renders a form section for selecting a platform and design.
       */}
      <fieldset
        style={{
          border: '1px solid #000',
          borderColor: '#D3D3D3',
          marginBottom: '20px',
        }}
      >
        <legend style={{ color: '#A9A9A9' }}>
          Choose Platform then Designs
        </legend>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '150px',
              marginRight: '35px',
            }}
          >
            <span style={{ marginTop: '10px' }}>Platform</span>
            <Select
              placeholder='Please select'
              defaultValue={intialPlatform}
              style={{
                width: '100%',
                marginTop: 10,
                marginBottom: 15,
              }}
              allowClear
              onChange={handleChange}
              options={
                platformUnion !== {}
                  ? createOptionsArray(
                      Object.keys(platformUnion)
                        .slice()
                        .sort((a, b) => {
                          return a.localeCompare(b, undefined, {
                            sensitivity: 'base',
                          });
                        }),
                    )
                  : []
              }
              disabled={
                value1 === '' ||
                !value1 ||
                value2 === '' ||
                !value2 ||
                Object.keys(platformUnion).length === 0
              }
              onClear={() => handlePlatformClear()}
              value={selectedPlatform}
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '150px' }}
          >
            <span style={{ marginTop: '10px' }}>Design</span>
            <CustomSelect
              style={{
                width: '100%',
                marginTop: 10,
                marginBottom: 10,
              }}
              onSelect={handleDesignChange}
              options={
                Object.keys(platformUnion).length !== 0 && selectedPlatform
                  ? createOptionsArray(
                      Object.keys(platformUnion[selectedPlatform])
                        .slice()
                        .sort((a, b) => {
                          return a.localeCompare(b, undefined, {
                            sensitivity: 'base',
                          });
                        }),
                    )
                  : []
              }
              disabled={
                !selectedPlatform || Object.keys(platformUnion).length === 0
              }
              defaultSelection={intialDesigns}
              clear={clearDesigns}
            />
          </div>
        </div>
      </fieldset>

      {/**
       * Conditionally renders a summary table of the designs status in the selected platform.
       */}
      {selectedDesigns &&
        !('error' in selectedDesigns) &&
        compareData !== {} && (
          <SummaryTable
            data={compareData?.compare}
            selectedDesigns={selectedDesigns}
            variant={variant}
            platform={selectedPlatform}
            onCellClick={handleChildData}
          />
        )}

      {/**
       * Renders a section for table filters with two buttons.
       *   - **PR Metrics Button**:
       *       - Triggers `handleClick` function on click.
       *   - **Clear All Button**:
       *       - Triggers `handleClearFilters` function on click.
       */}
      <fieldset
        style={{
          border: '1px solid #000',
          borderColor: '#D3D3D3',
          marginBottom: '20px',
        }}
      >
        <legend style={{ color: '#A9A9A9' }}>Table Filters</legend>
        <Button
          type='primary'
          style={
            prFilterClicked
              ? {
                  backgroundColor: '#52c41a',
                  borderColor: '#52c41a',
                  marginRight: '20px',
                }
              : {
                  backgroundColor: '#ffffff',
                  borderColor: '#52c41a',
                  marginRight: '20px',
                  color: '#52c41a',
                }
          }
          onClick={handleClick}
        >
          PR Metrics
        </Button>

        <Button danger onClick={handleClearFilters}>
          Clear All
        </Button>
      </fieldset>

      {/**
       * Conditionally renders export button and data table based on provided data.
       *
       * **Content**:
       * - **ExportButton**:
       *     - Filename for export: "{value1}_VS_{value2}_{selectedPlatform}.csv".
       *
       * - **Table**:
       *   - **`Table` Component**:
       *     - Displays data from `compareData.metrics` filtered based on `selectedFilters` and `filterType`.
       *     - Pagination is disabled, with large size and scrolling enabled.
       *     - Columns:
       *       - **Metrics Column**:
       *         - Title: "Metrics".
       *         - Data index: "metrics".
       *         - Filters: Configured with `filtersList` if available.
       *         - Search: Enabled.
       *       - **Design Columns**:
       *         - Grouped by each design in `selectedDesigns`.
       *         - **Base Column**:
       *           - Title: "Base".
       *           - Data index: Constructed from design, `value1`, and `buildId1`.
       *           - Align: Center.
       *           - Render function: Formats and rounds numbers, handles non-numeric values.
       *         - **Test Column**:
       *           - Title: "Test".
       *           - Data index: Constructed from design, `value2`, and `buildId2`.
       *           - Align: Center.
       *           - Render function: Formats and rounds numbers, handles non-numeric values.
       *         - **Comparison Column**:
       *           - Title: "Comparison".
       *           - Key: "comparison".
       *           - Align: Center.
       *           - Render function: Calculates and displays the difference and percentage difference between base and test values.
       *             - If the difference is significant, it is displayed with the appropriate color and format based on status (degradations, improvements, failed_metrics).
       *             - If the status is not defined, only the difference is displayed.
       *
       * **Notes**:
       * - Ensure `compareData`, `selectedDesigns`, and other dependencies are available and correctly structured to avoid rendering issues.
       */}
      {selectedDesigns &&
        !('error' in selectedDesigns) &&
        compareData !== {} &&
        !('error' in compareData) && (
          <div>
            <ExportButton
              compareData={compareData}
              selectedFilters={selectedFilters}
              filterType={filterType}
              selectedDesigns={selectedDesigns}
              value1={value1}
              value2={value2}
              buildId1={buildId1}
              buildId2={buildId2}
              style={{
                backgroundColor: '#ffffff',
                borderColor: '#1e90ff',
                color: '#1e90ff',
                marginBottom: '20px',
              }}
              filename={
                value1 + '_VS_' + value2 + '_' + selectedPlatform + '.csv'
              }
            />
            <Table
              dataSource={compareData?.metrics?.filter(item => {
                if (selectedFilters.length === 0) {
                  return true;
                }
                return selectedFilters.includes(item[filterType]);
              })}
              pagination={false}
              bordered
              size='large'
              scroll={{
                x: 'calc(700px + 50%)',
                y: '40vw',
              }}
            >
              <Column
                title='Metrics'
                dataIndex='metrics'
                key='metrics'
                fixed='left'
                filters={filtersList ? createFiltersArray(filtersList) : []}
                filterMode='tree'
                filterSearch={true}
                width={100}
                {...getColumnSearchProps('metrics')}
              />
              {selectedDesigns?.map((design, index) => (
                <ColumnGroup key={index} title={design}>
                  <Column
                    title='Base'
                    dataIndex={design + '/' + value1 + buildId1}
                    key={design + '/' + value1 + buildId1}
                    align='center'
                    width={100}
                    render={record => {
                      if (isNaN(record) || !record) {
                        return (
                          <div style={{ textAlign: 'center' }}>{record}</div>
                        );
                      }
                      return (
                        <div style={{ textAlign: 'center' }}>
                          {roundToFirstNonZero(record)}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title='Test'
                    dataIndex={design + '/' + value2 + buildId2}
                    key={design + '/' + value2 + buildId2}
                    align='center'
                    width={100}
                    render={record => {
                      if (isNaN(record) || !record) {
                        return (
                          <div style={{ textAlign: 'center' }}>{record}</div>
                        );
                      }
                      return (
                        <div style={{ textAlign: 'center' }}>
                          {roundToFirstNonZero(record)}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title='Comparison'
                    key='comparison'
                    align='center'
                    width={100}
                    render={record => {
                      const sourceA = design + '/' + value1 + buildId1;
                      const sourceB = design + '/' + value2 + buildId2;
                      if (
                        record[sourceA] === undefined ||
                        record[sourceB] === undefined ||
                        record[sourceA] === null ||
                        record[sourceB] === null ||
                        isNaN(record[sourceA]) ||
                        isNaN(record[sourceB]) ||
                        record?.metrics === 'build_id'
                      ) {
                        return <div style={{ textAlign: 'center' }}>-</div>;
                      }
                      const col1 = parseFloat(record[sourceA]);
                      const col2 = parseFloat(record[sourceB]);
                      let diff = col2 - col1;
                      if (Math.abs(diff) > 1) {
                        diff = diff.toFixed(2);
                      } else if (Math.abs(diff) === 0) {
                        diff = 0;
                      } else {
                        diff = diff.toExponential(2);
                      }
                      const perDiffBase = (
                        (Math.abs(diff) / Math.abs(col1)) *
                        100
                      ).toFixed(2);
                      const perDiff =
                        col1 !== 0
                          ? perDiffBase > 0
                            ? '+' + perDiffBase + '%'
                            : perDiffBase + '%'
                          : record?.metrics.includes('timing')
                          ? '100%'
                          : undefined;
                      if (record['status' + '/' + design] === 'degradations') {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              color: 'orange',
                              textAlign: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <span>{diff}</span>
                            <span>{perDiff}</span>
                          </div>
                        );
                      } else if (
                        record['status' + '/' + design] === 'improvements'
                      ) {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              color: 'green',
                              textAlign: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <span>{diff}</span>
                            <span>{perDiff}</span>
                          </div>
                        );
                      } else if (
                        record['status' + '/' + design] === 'failed_metrics'
                      ) {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              color: 'red',
                              textAlign: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <span>{diff}</span>
                            <span>{perDiff}</span>
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ textAlign: 'center' }}>{diff}</div>
                        );
                      }
                    }}
                  />
                </ColumnGroup>
              ))}
            </Table>
          </div>
        )}
      {/* </div> */}
    </div>
  );
};

export default ComparisonTable;

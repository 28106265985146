/**
 * @module App
 *
 * @description
 * The entry point of the React application. This module sets up routing, handles authentication checks, and integrates with the Google OAuth provider and react-query for caching.
 * It also configures routes for the main pages such as metrics trends, comparison tables, and statistical significance.
 */
import React, { useEffect } from 'react';
import './App.css';
import MetricsTrendsPage from './pages/MetricsTrendsPage';
import ComparisonTable from './pages/ComparisonPage';
import StatisticalSignificancePage from './pages/StatisticalSignificancePage';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CustomLayout from './components/Headers/CustomLayout';
const queryClient = new QueryClient();
import { theme } from 'antd';
import LandingPage from './pages/LandingPage';
import AdminPage from './pages/AdminPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSelector, useDispatch } from 'react-redux';
import { checkTokenExpiration } from './features/auth/authSlice';

/**
 * Main App component that manages routing, authentication, and page rendering for the application.
 *
 * @component
 * @returns {React.Element} The main application component.
 *
 * @example
 * <App />
 */
const App = () => {
  theme.defaultConfig.hashed = false;
  const { userInfo } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  /**
   * Save the user info to localStorage when the window is unloaded.
   */
  window.addEventListener('unload', () => {
    const serializedState = JSON.stringify(userInfo);
    localStorage.setItem('userInfo', serializedState);
  });

  /**
   * useEffect to check token expiration every 2 minutes.
   * On component mount, sets up an interval to check for token expiration
   * using the `checkTokenExpiration` action.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(checkTokenExpiration());
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <GoogleOAuthProvider clientId='281156998478-v5p8nj171g3sarj8a9r1ur1ag6sooje3.apps.googleusercontent.com'>
      <QueryClientProvider client={queryClient}>
        <div className='App'>
          <header className='App-header'>
            <BrowserRouter>
              <Routes>
                <Route exact path='/' element={<LandingPage />} />
                <Route
                  exact
                  path='/admin'
                  element={
                    <CustomLayout>
                      <AdminPage />
                    </CustomLayout>
                  }
                />
                {/* </Routes> */}
                {/* <CustomLayout> */}
                {/* <Route element={<CustomLayout />}> */}
                <Route
                  path='/trends'
                  element={
                    <CustomLayout>
                      <MetricsTrendsPage />
                    </CustomLayout>
                  }
                />
                <Route
                  path='/compare'
                  element={
                    <CustomLayout>
                      <ComparisonTable />
                    </CustomLayout>
                  }
                />
                <Route
                  path='/significance'
                  element={
                    <CustomLayout>
                      <StatisticalSignificancePage />
                    </CustomLayout>
                  }
                />
                {/* </Route> */}
              </Routes>
              {/* </CustomLayout> */}
            </BrowserRouter>
          </header>
        </div>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;

/**
 * @module components/MetricTrends/LineChart
 *
 * @description
 * This module provides the `LineChartWithMultiLines` component, which renders a composite line chart with multiple lines, overlay rules, and brushing capabilities.
 * It visualizes metric trends over a specified date range and supports zooming and overlay chart options.
 *
 * @see {@link ./CustomTooltip} for the tooltip component used in the chart.
 */
import React, { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { add, differenceInCalendarDays } from 'date-fns';
import { Scatter, Brush, Line, ComposedChart } from 'recharts';
import CustomTooltip from './CustomTooltip';

const colors = [
  '#FF6600',
  '#0088FE',
  '#FF4567',
  '#8884d8',
  '#83a6ed',
  '#8dd1e1',
  '#82ca9d',
  '#a4de6c',
  '#d0ed57',
  '#ffc658',
  '#ffa94d',
  '#ff7c43',
  '#ff6b81',
];

/**
 * Renders antd scatter and line elements based on the provided lines configuration.
 *
 * @param {Array} lines - An array of line configurations where each line has a `dataKey` for the data field.
 * @returns {Array} An array of Recharts `Scatter` components.
 */
const renderLines = lines => {
  return lines.map((line, index) => (
    <Scatter
      key={line.dataKey}
      dataKey={line.dataKey}
      fill={colors[index]}
      line
      shape='circle'
    />
  ));
};

/**
 * Renders rules indicator lines based on the provided lines configuration.
 *
 * @param {Array} lines - An array of line configurations where each line has a `dataKey` for the data field.
 * @returns {Array} An array of Recharts `Line` components.
 */
const renderRules = lines => {
  return lines.map((line, index) => (
    <Line
      key={'rule' + '/' + line.dataKey}
      dataKey={'rule' + '/' + line.dataKey}
      stroke={colors[index]}
      dot={false}
      strokeWidth={2}
      strokeDasharray='3 3'
      activeDot={false}
      legendType='none'
    />
  ));
};

/**
 * Renders brush elements based on the provided lines configuration.
 *
 * @param {Array} lines - An array of line configurations where each line has a `dataKey` for the data field.
 * @returns {Array} An array of Recharts `Brush` components.
 */
const renderBrush = lines => {
  return lines.map((line, index) => (
    <Brush
      height={30}
      stroke='#8884d8'
      key={line.dataKey}
      dataKey={line.dataKey}
      fill={colors[index]}
      line
      shape='circle'
      background={{ fill: '#eee' }}
    />
  ));
};

/**
 * A line chart component with multiple lines and optional rules lines.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.lines - An array of objects specifying the lines to be drawn on the chart. Each object should have a `dataKey` to reference the data field.
 * @param {string} props.title - The title of the chart.
 * @param {Date} [props.startDate=new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000)] - The start date for the chart data.
 * @param {Date} [props.endDate=new Date()] - The end date for the chart data.
 * @param {Array} props.data - The data to be displayed on the chart, where each item includes a date and value fields.
 * @param {Array} props.rulesData - Data for rule lines to be rendered on the chart.
 * @param {boolean} props.showOverlayChart - Flag to determine if overlay rules lines should be shown.
 *
 * @returns {React.Element} - The rendered chart component.
 *
 * @example
 * const lines = [{ dataKey: 'metricA' }, { dataKey: 'metricB' }];
 * const data = [{ date: '2024-09-07', metricA: 100, metricB: 200 }];
 * <LineChartWithMultiLines
 *   lines={lines}
 *   title="Metrics Overview"
 *   startDate={new Date('2024-09-01')}
 *   endDate={new Date('2024-09-30')}
 *   data={data}
 *   rulesData={rulesData}
 *   showOverlayChart={true}
 * />
 */
const LineChartWithMultiLines = ({
  lines,
  title,
  startDate = new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000),
  endDate = new Date(),
  data,
  rulesData,
  showOverlayChart,
}) => {
  /**
   * Generates tick marks for the X-axis based on the start and end dates.
   *
   * @param {Date} startDate - The start date for the ticks.
   * @param {Date} endDate - The end date for the ticks.
   * @param {number} num - The number of tick marks to generate.
   * @returns {number[]} An array of tick mark timestamps.
   */
  const getTicks = (startDate, endDate, num) => {
    const diffDays = differenceInCalendarDays(endDate, startDate);

    const current = startDate;
    const velocity = Math.round(diffDays / (num - 1));

    const ticks = [startDate?.getTime()];

    for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, { days: i * velocity }).getTime());
    }

    ticks.push(endDate?.getTime());
    return ticks;
  };
  const [ticks, setTicks] = useState(getTicks(startDate, endDate, 5));
  const [xDomain, setXDomain] = useState([
    () => startDate.getTime(),
    () => endDate.getTime(),
  ]);

  useEffect(() => {
    setTicks(getTicks(startDate, endDate, 5));
    setXDomain([() => startDate.getTime(), () => endDate.getTime()]);
  }, [startDate, endDate]);

  /**
   * Fills missing data points in the data array to align with tick marks.
   *
   * @param {number[]} _ticks - The tick marks to align data points with.
   * @param {Object[]} data - The data to be aligned.
   * @returns {Object[]} The aligned data array.
   */
  const fillTicksData = (_ticks, data) => {
    const ticks = [..._ticks];
    const filled = [];
    let currentTick = ticks.shift();
    let lastData = null;
    for (const it of data) {
      if (ticks.length && it.date > currentTick && lastData) {
        filled.push({ ...lastData, ...{ date: currentTick } });
        currentTick = ticks.shift();
      } else if (ticks.length && it.date === currentTick) {
        currentTick = ticks.shift();
      }

      filled.push(it);
      lastData = it;
    }

    return filled;
  };

  const [zoomData, setZoomData] = useState(data);

  /**
   * Handles brush change events to update the zoomed data range.
   *
   * @param {Object} domain - The domain object from the brush component.
   * @param {number} domain.startIndex - The start index of the brush selection.
   * @param {number} domain.endIndex - The end index of the brush selection.
   */
  const handleBrushChange = domain => {
    console.debug('domain', domain);
    const newData = data.filter(
      item => item.date >= domain?.startIndex && item.date <= domain?.endIndex,
    );
    setZoomData(newData);
  };

  const filteredData = data.filter(
    entry => entry.date >= startDate && entry.date <= endDate,
  );

  return (
    <>
      <span style={{ textAlign: 'center' }}>{title}</span>
      <ResponsiveContainer width='100%' height={300}>
        <ComposedChart
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          label={title}
          title={title}
          data={filteredData}
          renderWithCanvas={true}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='date'
            scale='time'
            type='number'
            domain={xDomain}
            ticks={ticks}
            tickFormatter={tick => new Date(tick).toLocaleDateString()}
          />
          <YAxis tick={{ fontSize: 12 }} padding={{ bottom: 10 }}>
            <Label
              value='Value'
              angle={-90}
              position='insideLeft'
              offset={-15}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {renderLines(lines)}
          {showOverlayChart && renderRules(lines)}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineChartWithMultiLines;

/**
 * @module components/MetricTrends/PlatformTab
 *
 * @description
 * This module contains the `PlatformTab` component which allows users to select designs, metrics, and date ranges to view metric trends.
 * It also provides options to toggle CI limits and handles loading states.
 *
 * @see {@link ./PlatformTab.css} for styles used by this component.
 */
import React, { useEffect, useState } from 'react';
import LineChartWithMultiLines from './LineChart';
import CustomSelect from './CustomSelect';
import './PlatformTab.css';
import { DatePicker, Button, Tabs, Spin, Switch } from 'antd';
import moment from 'moment';
import {
  createOptionsArray,
  convertArrayToObject,
  convertDates,
  filterDesigns,
} from '../../utils/util';
import {
  useGetDesignsQuery,
  useGetMetricTrendsQuery,
} from '../../services/api';

const { RangePicker } = DatePicker;

/**
 * PlatformTab component allows users to select designs, metrics, and date ranges to view metric trends for a platform.
 * It also provides options to toggle CI limits and handles loading states.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.tab - The current tab identifier.
 * @param {string} props.selectedPlatform - The currently selected platform.
 * @param {string} props.branchName - The name of the branch for querying metrics.
 * @param {Function} props.handleMetricChange - Function to handle metric selection changes.
 * @param {Array} props.selectedMetrics - List of selected metrics.
 * @param {Function} props.setselectedMetrics - Function to set selected metrics.
 * @param {boolean} props.clearMetrics - Flag to clear metrics.
 * @param {Function} props.setClearMetrics - Function to set the flag for clearing metrics.
 * @param {Array} props.defaultMetrics - List of default metrics to be plotted.
 *
 * @returns {React.Element} The rendered PlatformTab component.
 *
 * @example
 * <PlatformTab
 *   tab="asap7"
 *   selectedPlatform="asap7"
 *   branchName="aes"
 *   handleMetricChange={handleMetricChange}
 *   selectedMetrics={selectedMetrics}
 *   setselectedMetrics={setselectedMetrics}
 *   clearMetrics={clearMetrics}
 *   setClearMetrics={setClearMetrics}
 *   defaultMetrics={defaultMetrics}
 * />
 */
const PlatformTab = ({
  tab,
  selectedPlatform,
  branchName,
  handleMetricChange,
  selectedMetrics,
  setselectedMetrics,
  clearMetrics,
  setClearMetrics,
  defaultMetrics,
}) => {
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [defaultDesign, setDefaultDesign] = useState(true);

  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000),
  );
  const [endDate, setEndDate] = useState(new Date());

  const [selectedRange, setSelectedRange] = useState(null);

  const [activeButton, setActiveButton] = useState(null);

  const [clearDesigns, setClearDesigns] = useState(false);

  const [loading, setLoading] = useState(true);

  const handleDesignChange = value => {
    setDefaultDesign(false);
    setSelectedDesigns(value.join(','));
    setClearDesigns(false);
  };

  const { data: designs = [], isLoading: isDesignsLoading = true } =
    useGetDesignsQuery(selectedPlatform);
  const variant = 'base';

  const { data: metricsData, isLoading: isMetricsLoading = true } =
    useGetMetricTrendsQuery(
      {
        branchName: branchName,
        platform: selectedPlatform,
        designs: selectedDesigns,
        variant: variant,
      },
      {
        skip:
          !branchName ||
          !selectedPlatform ||
          !selectedDesigns ||
          selectedDesigns.length === 0 ||
          !variant,
      },
    );

  const metrics = metricsData ? Object.keys(metricsData) : [];

  const handleDesignsClear = () => {
    setClearMetrics(true);
    setselectedMetrics([]);
  };

  const handleDateChange = dates => {
    setStartDate(dates[0]?.toDate());
    setEndDate(dates[1]?.toDate());
    setSelectedRange(dates);
    console.debug('Selected dates:', dates[0]?.toDate(), dates[1]?.toDate());
  };

  /**
   * Handles predefined past date options.
   *
   * @param {string} option - The predefined date range option ("1day", "1week", "1month").
   */
  const handlePastOption = option => {
    const endDateC = moment().endOf('day');
    let startDateC;

    if (option === '1day') {
      startDateC = moment().subtract(1, 'day').startOf('day');
      setActiveButton('button1');
      setSelectedRange(null);
    } else if (option === '1week') {
      startDateC = moment().subtract(1, 'week').startOf('day');
      setActiveButton('button2');
      setSelectedRange(null);
    } else if (option === '1month') {
      startDateC = moment().subtract(1, 'month').startOf('day');
      setActiveButton('button3');
      setSelectedRange(null);
    }

    console.debug('Selected option:', option);
    console.debug('Selected date range:', [
      startDateC?.toDate(),
      endDateC?.toDate(),
    ]);
    setStartDate(startDateC?.toDate());
    setEndDate(endDateC?.toDate());
  };

  /**
   * Determines if a start date is disabled.
   *
   * @param {moment.Moment} startDateD - The start date to check.
   * @returns {boolean} Whether the start date is disabled.
   */
  const disabledStartDate = startDateD => {
    const endDateD = moment().endOf('day');
    const maxStartDate = moment().subtract(3, 'months').startOf('day');
    return (
      startDateD.valueOf() > endDateD.valueOf() ||
      startDateD.valueOf() < maxStartDate.valueOf()
    );
  };

  /**
   * Determines if an end date is disabled.
   *
   * @param {moment.Moment} endDateE - The end date to check.
   * @returns {boolean} Whether the end date is disabled.
   */
  const disabledEndDate = endDateE => {
    const maxEndDate = moment().endOf('day');
    return endDateE.valueOf() > maxEndDate.valueOf();
  };

  useEffect(() => {
    if (defaultDesign) {
      setSelectedDesigns(filterDesigns(designs, false).join(','));
      setClearDesigns(false);
      if (defaultMetrics) {
        setselectedMetrics(metricsData ? Object.keys(metricsData) : {});
        setClearMetrics(false);
      }
    }
  }, [designs, metricsData]);

  const [renderLoading, setRenderLoading] = useState(true);

  useEffect(() => {
    // Simulating loading time with a setTimeout
    const timer = setTimeout(() => {
      setRenderLoading(false);
      setLoading(false);
    }, 2000);

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, [metricsData]);

  const [showOverlayChart, setShowOverlayChart] = useState(false);

  const handleToggleOverlayChart = () => {
    setShowOverlayChart(!showOverlayChart);
  };

  return (
    <div>
      {isDesignsLoading || isMetricsLoading || loading ? (
        <Spin
          spinning={true}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '150px',
              }}
            >
              <span>Design</span>
              <CustomSelect
                style={{
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 15,
                }}
                onSelect={handleDesignChange}
                options={designs ? createOptionsArray(designs) : []}
                clear={clearDesigns}
                onClear={handleDesignsClear}
                selectDefault={true}
                isDesigns={true}
                key={'designs' + tab}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '150px',
              }}
            >
              <span>Metrics</span>
              <CustomSelect
                style={{
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 15,
                }}
                options={metrics ? createOptionsArray(metrics) : []}
                onSelect={handleMetricChange}
                clear={clearMetrics}
                selectDefault={true}
                key={'metrics' + tab}
                defaultSelection={selectedMetrics ? selectedMetrics : []}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '65vw',
            }}
          >
            <span
              style={{
                color: 'gray',
                textAlign: 'center',
                fontStyle: 'italic',
                marginBottom: '5px',
              }}
            >
              The metrics trend data is currently limited to 3 months ago
            </span>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <RangePicker
                onCalendarChange={handleDateChange}
                value={selectedRange}
                width={0}
                disabledDate={disabledStartDate}
                disabledEndDate={disabledEndDate}
                defaultValue={[startDate, endDate]}
              />
              <Button
                className={activeButton === 'button1' ? 'active' : ''}
                onClick={() => handlePastOption('1day')}
              >
                1 Day Ago
              </Button>
              <Button
                className={activeButton === 'button2' ? 'active' : ''}
                onClick={() => handlePastOption('1week')}
              >
                1 Week Ago
              </Button>
              <Button
                className={activeButton === 'button3' ? 'active' : ''}
                onClick={() => handlePastOption('1month')}
              >
                1 Month Ago
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                margin: '15px 0px 0px 25px',
              }}
            >
              <span
                style={{
                  marginRight: '10px',
                  color: 'gray',
                  fontStyle: 'italic',
                }}
              >
                Show CI Limits
              </span>
              <Switch
                checked={showOverlayChart}
                onChange={handleToggleOverlayChart}
                defaultChecked={false}
                style={{ width: '40px', order: '1' }}
              />
            </div>
            {Object.keys(selectedMetrics).length !== 0 &&
            Object.keys(metricsData).length !== 0 ? (
              <Spin spinning={renderLoading} style={{ textAlign: 'center' }}>
                {Object.keys(metricsData).length !== 0 &&
                  selectedMetrics?.map((metric, index) => (
                    <LineChartWithMultiLines
                      key={index}
                      title={metric}
                      lines={
                        selectedDesigns &&
                        Object.keys(selectedDesigns).length !== 0
                          ? convertArrayToObject(
                              selectedDesigns.split(','),
                              'dataKey',
                            )
                          : []
                      }
                      data={
                        metricsData ? convertDates(metricsData)[metric] : {}
                      }
                      startDate={startDate}
                      endDate={endDate}
                      showOverlayChart={showOverlayChart}
                    />
                  ))}
              </Spin>
            ) : (
              <p style={{ textAlign: 'center', color: 'red' }}>
                There is no data to display
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformTab;

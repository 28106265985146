import React from 'react';
import { Table, Collapse } from 'antd';

const { Column } = Table;

/**
 * @module components/Comparison/PlatformsSummaryTable
 *
 * @description
 * PlatformsSummaryTable component displays a summary table with platform's count of degradation, failed metrics, improvement and no change.
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @param {Array<Object>} props.data - The table data, where each object represents a row with status and platform data.
 * @param {Array<string>} props.platformUnion - List of platform names to display as columns.
 * @param {string} props.variant - The variant type.
 * @param {Function} props.onCellClick - Function to handle click events on a table cell.
 * @param {boolean} props.loading - Boolean to indicate if the table is in a loading state.
 * @param {string} [props.title="Summary Table"] - Title for the table.
 *
 * @returns {React.Element} A table displaying platform summaries and statuses.
 *
 * @example
 * const data = [
 *   { status: "Improvement", asap7: "Pass", gf180: "Fail" },
 *   { status: "Failed Metrics", asap7: "Pass", gf180: "Pass" },
 * ];
 * const platformUnion = ["asap7", "gf180"];
 *
 * <PlatformsSummaryTable
 *   data={data}
 *   platformUnion={platformUnion}
 *   variant="base"
 *   onCellClick={(record) => console.log(record)}
 *   loading={false}
 * />
 */
const PlatformsSummaryTable = ({
  data,
  platformUnion,
  variant,
  onCellClick,
  loading,
  title = 'Summary Table',
}) => {
  return (
    <Table
      key='summary'
      dataSource={data}
      pagination={false}
      bordered
      size='large'
      scroll={{
        x: 'calc(700px + 50%)',
        y: '40vw',
      }}
      loading={loading}
    >
      <Column
        title='Status'
        dataIndex='status'
        key='status'
        fixed='left'
        width={0}
      />
      {platformUnion?.map((platform, index) => (
        <Column
          width={0}
          title={platform}
          dataIndex={platform}
          key={platform}
          render={text => {
            return (
              <div key={index} style={{ textAlign: 'center' }}>
                {text}
              </div>
            );
          }}
        />
      ))}
    </Table>
  );
};

export default PlatformsSummaryTable;

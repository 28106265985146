import React from 'react';
import { Button } from 'antd';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

/**
 * @module components/Comparison/ExportButton
 *
 * @description
 * ExportButton component for exporting comparison data as a CSV file.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Array} props.compareData - Array of comparison metrics data to export.
 * @param {Array} props.selectedFilters - The filters currently applied to the data.
 * @param {string} props.filterType - The type of filter being used.
 * @param {Array} props.selectedDesigns - Array of selected designs to include in the export.
 * @param {string} props.value1 - The value representing the base value.
 * @param {string} props.value2 - The value representing the test value.
 * @param {string} props.buildId1 - The ID of the base build to compare.
 * @param {string} props.buildId2 - The ID of the test build to compare.
 * @param {Object} [props.style] - Optional styling to apply to the button.
 * @param {string} [props.filename="export.csv"] - The filename for the exported CSV file.
 *
 * @returns {React.Element} The ExportButton component.
 *
 * @example
 * <ExportButton
 *   compareData={metricsData}
 *   selectedFilters={["filter1", "filter2"]}
 *   filterType="metric"
 *   selectedDesigns={["design1", "design2"]}
 *   value1="1234"
 *   value2="5678"
 *   buildId1="build1"
 *   buildId2="build2"
 * />
 */
const ExportButton = ({
  compareData,
  selectedFilters,
  filterType,
  selectedDesigns,
  value1,
  value2,
  buildId1,
  buildId2,
  style,
  filename = 'export.csv',
}) => {
  const roundToFirstNonZero = num => {
    if (num === 0) return '0';
    const parts = num.toString().split('.');
    if (parts.length === 1) return parts[0];
    return `${parts[0]}.${parts[1].substring(0, 1)}`;
  };

  /**
   * Generates additional rows for iterations of a metric up to 64.
   * It is meant to unify files generated for different builds
   *
   * @param {string} metricBase - The base name of the metric.
   * @param {Array} selectedDesigns - The selected designs.
   * @param {string} value1 - The first value for comparison.
   * @param {string} value2 - The second value for comparison.
   * @param {string} buildId1 - The ID of the first build.
   * @param {string} buildId2 - The ID of the second build.
   * @param {number} startIter - The starting iteration number.
   * @returns {Array<Object>} The rows for iterations.
   */
  const generateIterRows = (
    metricBase,
    selectedDesigns,
    value1,
    value2,
    buildId1,
    buildId2,
    startIter,
  ) => {
    const rows = [];
    for (let i = startIter + 1; i <= 64; i++) {
      const row = { Metrics: `${metricBase}__iter__${i}` };
      selectedDesigns.forEach(design => {
        row[`${design} Base`] = '-';
        row[`${design} Test`] = '-';
        row[`${design} Comparison`] = '-';
      });
      rows.push(row);
    }
    return rows;
  };

  const exportToCSV = () => {
    const dataToExport = compareData?.metrics?.filter(item => {
      if (selectedFilters.length === 0) {
        return true;
      }
      return selectedFilters.includes(item[filterType]);
    });

    const defaultMetrics = [
      'finish__design_powergrid__drop__average__net__VDD__corner__default',
      'finish__design_powergrid__drop__average__net__VSS__corner__default',
      'finish__design_powergrid__drop__worst__net__VDD__corner__default',
      'finish__design_powergrid__drop__worst__net__VSS__corner__default',
      'finish__design_powergrid__voltage__worst__net__VDD__corner__default',
      'finish__design_powergrid__voltage__worst__net__VSS__corner__default',
    ];

    defaultMetrics.forEach(defaultMetric => {
      if (!dataToExport.find(item => item.metrics === defaultMetric)) {
        const defaultRow = { metrics: defaultMetric };
        selectedDesigns.forEach(design => {
          const base = `${design}/${value1}${buildId1}`;
          const test = `${design}/${value2}${buildId2}`;
          defaultRow[base] = '-';
          defaultRow[test] = '-';
        });
        dataToExport.push(defaultRow);
      }
    });

    const fields = [
      'Metrics',
      ...selectedDesigns.flatMap(design => [
        `${design} Base`,
        `${design} Test`,
        `${design} Comparison`,
      ]),
    ];

    const csvData = [];
    const iterPattern = /__iter__(\d+)/;
    const iterMap = new Map();

    // first pass: add original rows and track the highest iteration for each metric base
    dataToExport.forEach(item => {
      const row = { Metrics: item.metrics };
      selectedDesigns.forEach(design => {
        const base = `${design}/${value1}${buildId1}`;
        const test = `${design}/${value2}${buildId2}`;
        const baseValue = parseFloat(item[base]);
        const testValue = parseFloat(item[test]);
        row[`${design} Base`] = isNaN(baseValue) ? '-' : baseValue;
        row[`${design} Test`] = isNaN(testValue) ? '-' : testValue;
        row[`${design} Comparison`] =
          isNaN(baseValue) || isNaN(testValue)
            ? '-'
            : roundToFirstNonZero(testValue - baseValue);
      });
      csvData.push(row);

      // Track the highest iteration number for each metric base
      const match = item.metrics.match(iterPattern);
      if (match) {
        const currentIter = parseInt(match[1], 10);
        const metricBase = item.metrics.split('__iter__')[0];
        if (!iterMap.has(metricBase)) {
          iterMap.set(metricBase, currentIter);
        } else {
          const maxIter = iterMap.get(metricBase);
          if (currentIter > maxIter) {
            iterMap.set(metricBase, currentIter);
          }
        }
      }
    });

    // generate additional rows for metrics with __iter__{n} up to 64
    iterMap.forEach((maxIter, metricBase) => {
      if (maxIter < 64) {
        csvData.push(
          ...generateIterRows(
            metricBase,
            selectedDesigns,
            value1,
            value2,
            buildId1,
            buildId2,
            maxIter,
          ),
        );
      }
    });

    // sort csvData to ensure metrics and their iterations are in order
    csvData.sort((a, b) =>
      a.Metrics.localeCompare(b.Metrics, undefined, { numeric: true }),
    );

    const csv = Papa.unparse(csvData, { columns: fields });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };

  return (
    <Button style={style} onClick={exportToCSV}>
      Export to CSV
    </Button>
  );
};

export default ExportButton;

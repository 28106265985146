/**
 * @module features/auth/authAction
 *
 * @description
 * Provides Redux asynchronous actions related to authentication, such as logging in the user.
 * Handles API requests to the backend to authenticate users and manage tokens.
 */

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const backendURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : 'http://localhost:80';

/**
 * Thunk action for logging in a user using an access token.
 * This function sends a GET request to the backend with the access token to authenticate the user.
 * If the login is successful, the user token is stored in the local storage. If not, it returns an error message.
 *
 * @function
 * @async
 * @param {string} accessToken - The OAuth access token used for user authentication.
 * @param {Object} thunkAPI - The thunkAPI object provided by Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Redux function used to handle rejected actions with a custom value.
 *
 * @returns {Object} The response data from the backend if successful, or a custom error message if login fails.
 *
 * @example
 * dispatch(loginUser(accessToken))
 *   .unwrap()
 *   .then((userData) => {
 *     console.log("User logged in successfully:", userData);
 *   })
 *   .catch((error) => {
 *     console.error("Login failed:", error);
 *   });
 */
export const loginUser = createAsyncThunk(
  'auth/login',
  async (accessToken, { rejectWithValue }) => {
    try {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      const { data } = await axios.get(
        `${backendURL}/login?accessToken=${accessToken}`,
      );

      // store user's token in local storage
      localStorage.setItem('userToken', data.token);
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

import React from 'react';
import { Table, Collapse } from 'antd';

const { Column } = Table;
const { Panel } = Collapse;

/**
 * @module components/Comparison/SummaryTable
 *
 * @description
 * SummaryTable component displays a collapsible table showing status summary of metrics per design per platform with clickable cells to filter comparison table.
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @param {Array<Object>} props.data - Data for the table rows, where each object represents a row with status and design/platform data.
 * @param {Array<string>} props.selectedDesigns - Array of design names to be displayed as table columns.
 * @param {string} props.variant - A string to specify the variant type.
 * @param {string} props.platform - Platform name used in the table header for each design column.
 * @param {Function} props.onCellClick - Function to handle click events on table cells, receiving the record and design name.
 * @param {boolean} props.loading - Boolean indicating whether the table is in a loading state.
 * @param {string} [props.title="Summary Table"] - Title for the collapse panel.
 *
 * @returns {React.Element} A collapsible summary table showing the status of selected designs on the platform.
 *
 * @example
 * const data = [
 *   { status: "Improvement", aes: 5, aes_lvt: 0 },
 *   { status: "Degradation", aes: 0, aes_lvt: 3 },
 * ];
 * const selectedDesigns = ["aes", "aes_lvt"];
 * const platform = "Platform1";
 *
 * <SummaryTable
 *   data={data}
 *   selectedDesigns={selectedDesigns}
 *   variant="base"
 *   platform={platform}
 *   onCellClick={(record, design) => console.log(record, design)}
 *   loading={false}
 * />
 */
const SummaryTable = ({
  data,
  selectedDesigns,
  variant,
  platform,
  onCellClick,
  loading,
  title = 'Summary Table',
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        marginBottom: '20px',
      }}
    >
      <Collapse defaultActiveKey={['1']}>
        <Panel header={title} key='1'>
          <Table
            key='summary'
            dataSource={data}
            pagination={false}
            bordered
            size='large'
            scroll={{
              x: 'calc(700px + 50%)',
              y: '40vw',
            }}
            loading={loading}
          >
            <Column
              title='Status'
              dataIndex='status'
              key='status'
              fixed='left'
              width={0}
            />
            {selectedDesigns?.map((design, index) => (
              <Column
                width={0}
                title={platform + ' ' + design + ' ' + variant}
                dataIndex={design}
                key={design}
                render={text => {
                  if (text != 0)
                    return (
                      <div key={index} style={{ textAlign: 'center' }}>
                        <a>{text}</a>
                      </div>
                    );
                  else
                    return (
                      <div key={index} style={{ textAlign: 'center' }}>
                        {text}
                      </div>
                    );
                }}
                onCell={record => {
                  return {
                    onClick: () => {
                      onCellClick(record, design);
                    },
                  };
                }}
              />
            ))}
          </Table>
        </Panel>
      </Collapse>
    </div>
  );
};

export default SummaryTable;

/**
 * @module components/MetricTrends/CustomSelect
 *
 * @description
 * This module exports the `CustomSelect` component, a customizable dropdown select menu used for selecting design or metric options.
 * It supports multiple options and can be cleared or reset based on user interactions.
 *
 * @see {@link ./CustomSelect.css} for styles used by this component.
 */
import React, { useEffect, useState } from 'react';
import { Select, Checkbox } from 'antd';
import './CustomSelect.css';
import { filterDesigns } from '../../utils/util';

const { Option } = Select;

/**
 * CustomSelect component for selecting multiple options, with an optional "Select All" functionality.
 * It can be customized for design-specific options and handles clearing selections, default selections, and more.
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @param {Array} props.options - The list of options available for selection. Each option should be an object with `value` and `label` properties.
 * @param {Function} props.onSelect - Callback function to handle selection changes. Receives the selected values as an argument.
 * @param {boolean} props.disabled - Disables the select dropdown if true.
 * @param {boolean} props.clear - Triggers clearing of selected options if true.
 * @param {Function} [props.onClear] - Callback function to be executed when the selection is cleared.
 * @param {boolean} props.selectDefault - If true, the default selection is applied based on the provided options.
 * @param {boolean} props.isDesigns - Indicates if the options are design-specific, used for applying design-specific filters.
 * @param {Array} props.defaultSelection - An array of default selected values.
 * @param {string} [props.placeholder="Please select"] - Placeholder text for the select dropdown.
 *
 * @returns {React.Element} A multi-select dropdown component with "Select All" and customizable behaviors.
 *
 * @example
 * const options = [{ value: '1', label: 'Option 1' }, { value: '2', label: 'Option 2' }];
 * <CustomSelect
 *   options={options}
 *   onSelect={handleSelect}
 *   disabled={false}
 *   clear={false}
 *   selectDefault={true}
 *   isDesigns={false}
 *   defaultSelection={['1']}
 * />
 */
const CustomSelect = ({
  options,
  onSelect,
  disabled,
  clear,
  onClear,
  selectDefault,
  isDesigns,
  defaultSelection,
  placeholder = 'Please select',
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectDefault
      ? isDesigns
        ? filterDesigns(options, true).map(option => option.value)
        : defaultSelection !== []
        ? defaultSelection
        : options.map(option => option.value)
      : [],
  );
  const [selectAll, setSelectAll] = useState(false);

  /**
   * Handles the "Select All" checkbox functionality, selecting or deselecting all options.
   *
   * @param {Event} e - The event object from the checkbox change.
   */
  const handleSelectAll = e => {
    const { checked } = e.target;
    const allValues = options.map(option => option.value);
    setSelectedOptions(checked ? allValues : []);
    onSelect(checked ? allValues : []);
    setSelectAll(checked);
  };

  /**
   * Handles individual option selection in the dropdown.
   *
   * @param {Array} value - The currently selected values.
   */
  const handleSelect = value => {
    setSelectedOptions(value);
    onSelect(value);
    setSelectAll(value.length === options.length);
  };

  /**
   * Resets the selected options and "Select All" state when the `clear` prop is triggered.
   */
  useEffect(() => {
    if (clear) {
      setSelectedOptions([]);
      setSelectAll(false);
    }
  }, [clear]);

  /**
   * Updates the selected options when the `defaultSelection` prop changes.
   */
  useEffect(() => {
    if (defaultSelection) {
      setSelectedOptions(defaultSelection);
    }
  }, [defaultSelection]);

  /**
   * Handles the clearing of selections, optionally triggering the `onClear` callback.
   */
  const handleClear = () => {
    if (typeof onClear === 'function') {
      setSelectedOptions([]);
      setSelectAll(false);
      onClear();
    }
  };

  return (
    <Select
      mode='multiple'
      onChange={handleSelect}
      value={selectedOptions}
      allowClear
      placeholder={placeholder}
      disabled={disabled}
      onClear={handleClear}
      className='custom-select'
    >
      {/* "Select All" option rendered if options are available */}
      {options.length !== 0 && (
        <Option key='select-all' value='select-all' disabled>
          <Checkbox checked={selectAll} onChange={handleSelectAll}>
            Select All
          </Checkbox>
        </Option>
      )}
      {/* Rendering each option from the provided options list */}
      {options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;

/**
 * @module utils
 * @description A collection of utility functions used across the application.
 */

/**
 * Creates an array of option objects with value and label properties.
 *
 * @param {string[]} stringsArray - The array of strings to convert.
 * @returns {Array<{value: string, label: string}>} An array of objects with value and label properties.
 */
export function createOptionsArray(stringsArray) {
  return stringsArray.map(str => ({ value: str, label: str }));
}

/**
 * Creates an array of filter objects with text and value properties.
 *
 * @param {string[]} stringsArray - The array of strings to convert.
 * @returns {Array<{text: string, value: string}>} An array of objects with text and value properties.
 */
export function createFiltersArray(stringsArray) {
  return stringsArray.map(str => ({ text: str, valule: str }));
}

/**
 * Converts an array into an array of objects using a specified key.
 *
 * @param {any[]} array - The array to convert.
 * @param {string} key - The key to use for the object property.
 * @returns {Array<Object>} An array of objects with the specified key.
 */
export function convertArrayToObject(array, key) {
  return array.map(item => ({ [key]: item }));
}

/**
 * Converts date strings in a response object to Date objects.
 *
 * @param {Object} response - The response object containing date strings.
 * @returns {Object} A new object with date strings converted to Date objects.
 */
export function convertDates(response) {
  const convertedResponse = {};

  Object.keys(response).forEach(key => {
    convertedResponse[key] = response[key].map(obj => ({
      ...obj,
      date: new Date(obj.date),
    }));
  });

  return convertedResponse;
}

/**
 * Transforms two objects into ant design table format, comparing their properties.
 *
 * @param {string} name1 - The name associated with the first object.
 * @param {string} name2 - The name associated with the second object.
 * @param {Object} objDesigns1 - The first object to compare.
 * @param {Object} objDesigns2 - The second object to compare.
 * @returns {Object[]} An array of objects representing the table rows.
 */
export const transformObjectsToTable = (
  name1,
  name2,
  objDesigns1,
  objDesigns2,
) => {
  console.debug(
    'params to transformed are',
    name1,
    name2,
    objDesigns1,
    objDesigns2,
  );
  if (
    !name1 ||
    !name2 ||
    objDesigns1 === { '': null } ||
    objDesigns2 === { '': null } ||
    'error' in objDesigns1 ||
    'error' in objDesigns2
  )
    return [];
  const transformedDataObj = {};
  // loop on designs

  const designs1 = objDesigns1 ? Object.keys(objDesigns1) : [];
  const designs2 = objDesigns2 ? Object.keys(objDesigns2) : [];

  const allDesigns = new Set([...designs1, ...designs2]); // Combine keys and remove duplicates
  allDesigns?.forEach(design => {
    const obj1 = design in objDesigns1 ? objDesigns1[design] : {};
    const obj2 = design in objDesigns2 ? objDesigns2[design] : {};
    const obj1list = obj1 ? Object.keys(obj1) : [];
    const obj2list = obj2 ? Object.keys(obj2) : [];

    const allMetrics = new Set([...obj1list, ...obj2list]); // Combine keys and remove duplicates
    allMetrics?.forEach(key => {
      let newObj = {};
      newObj.metrics = key;
      if (key in transformedDataObj) {
        newObj = transformedDataObj[key];
      }
      // Assign each object name as the key to the value of the key in the previous object
      const key1 = design + '/' + name1;
      const key2 = design + '/' + name2;
      newObj[key1] = '';
      newObj[key2] = '';
      // Discard array values or nested objects
      if (typeof obj1 === 'object' && key in obj1) {
        if (Array.isArray(obj1[key]) || typeof obj1[key] === 'object') {
          return;
        }
        newObj[key1] = key in obj1 ? obj1[key] : '';
      }
      if (typeof obj2 === 'object' && key in obj2) {
        if (Array.isArray(obj2[key]) || typeof obj2[key] === 'object') {
          return;
        }
        newObj[key2] = key in obj2 ? obj2[key] : '';
      }

      transformedDataObj[key] = newObj;
    });
  });
  console.debug('transformed data obj', transformedDataObj);

  const transformedDataList = transformedDataObj
    ? Object.keys(transformedDataObj)
    : [];
  const transformedData = {};
  transformedData['metrics'] = transformedDataList;
  transformedData['data'] = [];
  transformedDataList?.forEach(metric => {
    transformedData['data'].push(transformedDataObj[metric]);
  });

  return transformedData;
};

/**
 * Rounds a number to the first non-zero decimal place.
 *
 * @param {number} number - The number to round.
 * @returns {number} The rounded number, or the original number if no rounding is needed.
 */
export function roundToFirstNonZero(number) {
  if (number && number !== Math.round(number)) {
    const str = number.toString();
    const match = str.match(/-?[1-9]+(\.[0-9]+)?/);
    // const match = str.match(/^\d+(\.\d+)?/);
    if (match) {
      const position = match.index + match[0].indexOf('.') + 1;
      const no = Number(number)?.toFixed(position);
      return Number(no).toFixed(2);
    }
  }
  return number;
}

/**
 * Returns a comparison operator function based on the provided string.
 *
 * @param {string} comparison - The comparison operator as a string.
 * @returns {function} A function that takes two arguments and compares them.
 * @throws {Error} If an unknown comparison operator is provided.
 */
export function operatorFromString(comparison) {
  switch (comparison) {
    case '==':
      return (a, b) => a == b;
    case '===':
      return (a, b) => a === b;
    case '!=':
      return (a, b) => a != b;
    case '!==':
      return (a, b) => a !== b;
    case '>':
      return (a, b) => a > b;
    case '>=':
      return (a, b) => a >= b;
    case '<':
      return (a, b) => a < b;
    case '<=':
      return (a, b) => a <= b;
    default:
      throw new Error(`Unknown comparison operator: ${comparison}`);
  }
}

/**
 * Filters an array of designs based on whether they match a default list.
 *
 * @param {Object[]|string[]} designs - An array of design objects or strings to filter.
 * @param {boolean} isOptions - Whether the designs are option objects with a `value` property.
 * @returns {Object[]|string[]} The filtered array of designs.
 */
export function filterDesigns(designs, isOptions) {
  const defaultDesigns = ['gcd', 'aes', 'ibex', 'jpeg'];

  if (isOptions) {
    return designs.filter(item => defaultDesigns.includes(item.value));
  }

  return designs.filter(item => defaultDesigns.includes(item));
}

/**
 * Extracts values associated with a specified key from an array of objects.
 *
 * @param {Object[]} arrayOfObjects - The array of objects to extract values from.
 * @param {string} keyToExtract - The key whose values should be extracted.
 * @returns {any[]} An array of values extracted from the objects.
 */
export function arrayFromObjects(arrayOfObjects, keyToExtract) {
  const valuesArray = [];
  arrayOfObjects.forEach(obj => {
    // Check if the key exists in the current object
    if (keyToExtract in obj) {
      // Extract the value associated with the specified key
      const value = obj[keyToExtract];
      // Add the value to the valuesArray
      valuesArray.push(value);
    }
  });

  return valuesArray;
}

/**
 * Truncates a string to a specified maximum length.
 *
 * @param {string} str - The string to truncate.
 * @param {number} maxLength - The maximum length of the truncated string.
 * @returns {string} The truncated string, or the original string if it is shorter than `maxLength`.
 */
export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength); // Truncate to the first 6 characters
  }
  return str; // The string is already 6 characters or shorter
}

/**
 * Splits a full name into first and last name components.
 *
 * @param {string} name - The full name to split.
 * @returns {{first: string, last: string}} An object with `first` and `last` properties.
 */
export function splitName(name) {
  if (name.includes(' ')) {
    const [first, last] = name.split(' ');
    return { first, last };
  } else {
    return { firt: name, last: '' };
  }
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - The string to capitalize.
 * @returns {string} The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Flattens the values of an object into an array.
 *
 * @param {Object} obj - The object whose values should be flattened.
 * @returns {any[]} An array of the object's values.
 */
export function flattenObjectValuesToArray(obj) {
  return Object.values(obj);
}

/**
 * @module components/Headers/CustomLayout
 *
 * @description
 * This module exports the `CustomLayout` component, which provides a layout structure including a header, sidebar, and content area.
 * It manages user authentication and displays dynamic menu items based on the user's role. The sidebar menu is collapsible,
 * and the header includes a greeting for the user and a login button if the user is not authenticated.
 *
 */
import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  BarChartOutlined,
  TableOutlined,
  LineChartOutlined,
  CalculatorOutlined,
  ToolOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { loginUser } from '../../features/auth/authAction';
const { Header, Content, Sider } = Layout;

/**
 * CustomLayout component for rendering the main layout structure including header, sidebar, and content area.
 * It manages user authentication and displays dynamic menu items based on the user's role.
 *
 * @component
 * @param {Object} props - The properties for the component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the layout's content area.
 *
 * @returns {React.Element} A layout component with header, sidebar, and dynamic content area.
 *
 * @example
 * <CustomLayout>
 *   <Dashboard />
 * </CustomLayout>
 */
const CustomLayout = ({ children }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const { loading, userInfo, error, success } = useSelector(
    state => state.auth,
  );
  const dispatch = useDispatch();

  /**
   * Function to handle Google login using the OAuth 2.0 flow.
   * On success, it dispatches the loginUser action with the Google access token.
   * Needed if user login using the nav bar.
   */
  const login = useGoogleLogin({
    onSuccess: credentialResponse => {
      console.debug(credentialResponse);
      dispatch(loginUser(credentialResponse['access_token']));
    },
    onError: () => {
      console.debug('Login Failed');
    },
  });

  return (
    <Layout>
      {/* Header section containing the application title and user greeting */}
      <Header
        style={{
          backgroundColor: '	#E8E8E8',
          paddingInline: '15px',
          borderBottomColor: 'black',
          borderBottom: '1px solid black',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Application title with link to the homepage */}
          <div>
            <BarChartOutlined
              style={{ fontSize: '30px', marginRight: '5px' }}
            />
            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
              <Link style={{ color: 'inherit', textDecoration: 'none' }} to='/'>
                OpenROAD Dashboard
              </Link>
            </span>
          </div>

          {/* User greeting and login button */}
          <div>
            <span style={{ fontSize: '20px' }}>
              Hello, {userInfo?.name ? userInfo?.name.split(' ')[0] : 'Guest'}
            </span>
            {!userInfo?.name && (
              <LoginOutlined
                style={{
                  marginLeft: '10px',
                  marginRight: '10px',
                  color: '#52c41a',
                }}
                onClick={() => login()}
              />
            )}
          </div>
        </div>
      </Header>

      <Layout>
        {/* Sidebar with collapsible menu containing different navigation options */}
        <Sider
          width={200}
          style={{ backgroundColor: 'black' }}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapse}
        >
          <Menu
            mode='inline'
            theme='dark'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
              backgroundColor: 'black',
              fontWeight: 'bold',
            }}
            selectedKeys={[location.pathname]}
          >
            {/* Admin menu item visible only if the user is an admin */}
            {userInfo?.role == 'Admin' && (
              <Menu.Item key='/admin' icon={<ToolOutlined />}>
                <Link to='/admin'>Admin</Link>
              </Menu.Item>
            )}
            <Menu.Item key='/trends' icon={<LineChartOutlined />}>
              <Link to='/trends'>Metrics Trend</Link>
            </Menu.Item>
            <Menu.Item key='/compare' icon={<TableOutlined />}>
              <Link to='/compare'>Compare</Link>
            </Menu.Item>
            <Menu.Item key='/significance' icon={<CalculatorOutlined />}>
              <Link to='/significance'>Statistical Significance</Link>
            </Menu.Item>
          </Menu>
        </Sider>

        {/* Main content area */}
        <Layout style={{ padding: '24px', backgroundColor: 'white' }}>
          <Content
            className='site-layout-background'
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;

/**
 * @module pages/StatisticalSignificancePage
 *
 * @description
 * This module contains the `StatisticalSignificancePage` component. This page provides a comprehensive interface for analyzing statistical significance in data. It includes features for adjusting the cutoff value, filtering data, and viewing statistical results in a grid format. The page fetches data from APIs, allows users to apply various filters, and displays results with relevant legends and abbreviations.
 */
import React, { useEffect, useState } from 'react';
import './StatisticalSignificancePage.css';
import { useGetPlatformsQuery, useGetDistDataQuery } from '../services/api';
import GridTable from '../components/StatisticalSignificance/Grid';
import { Spin, Button, InputNumber } from 'antd';
import SummaryGrid from '../components/StatisticalSignificance/SummaryGrid';
import ColorLegend from '../components/StatisticalSignificance/ColorLegend';
import AbbreviationTable from '../components/StatisticalSignificance/AbbreviationTable';

/**
 * `StatisticalSignificancePage` is a React component that provides a user interface for analyzing statistical significance of data. It allows users to set a cutoff value, apply filters, and view data in a grid format. The page also includes legends and a table of abbreviations to aid in understanding the displayed data.
 *
 * @function StatisticalSignificancePage
 * @returns {JSX.Element} The component renders the page with various sections:
 * - A fieldset for setting the percentile cutoff value.
 * - A summary grid displaying statistical data.
 * - A fieldset for applying and clearing table filters.
 * - A table displaying design abbreviations.
 * - A color legend explaining data classifications.
 * - A grid table showing detailed statistical significance data.
 */
const StatisticalSignificancePage = () => {
  const { data: platforms } = useGetPlatformsQuery();

  const [cutOffValue, setCutOffValue] = useState(5);

  const percentageValue = cutOffValue * 0.01;

  const { data: dataSource, isLoading } = useGetDistDataQuery({
    cutOffValue: percentageValue,
    forceUpdate: 'false',
  });

  const [prFilterClicked, setprFilterClicked] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterType, setFilterType] = useState('');

  /**
   * Handles the application of predefined filters when the button is clicked.
   * Sets the `prFilterClicked` state to true, applies predefined filters, and sets the filter type to "metric".
   */
  const handleClick = () => {
    setprFilterClicked(true);
    setSelectedFilters([
      'detailedroute__route__wirelength',
      'cts__design__instance__count__hold_buffer',
      'finish__design__instance__area',
      'finish__timing__drv__max_slew',
      'finish__power__total',
      'floorplan__power__internal__total',
      'floorplan__power__switching__total',
      'finish__timing__setup__ws',
      'finish__timing__setup__tns',
    ]);
    setFilterType('metric');
  };

  /**
   * Clears all filters and resets filter states.
   */
  const handleClearFilters = () => {
    setprFilterClicked(false);
    setSelectedFilters([]);
    setFilterType('');
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    /**
     * Simulates an asynchronous operation, such as data fetching.
     * This function is used to demonstrate a loading state.
     */
    const simulateAsyncOperation = async () => {
      setLoading(true);

      await new Promise(resolve => setTimeout(resolve, 2500));

      setLoading(false);
    };

    simulateAsyncOperation();
  }, [cutOffValue]);

  const legendData = [
    { label: 'Normal', color: 'green' },
    { label: 'Good Outlier', color: '#7CB9E8' },
    { label: 'Bad Outlier', color: 'orange' },
    { label: 'Good PR Outlier', color: '#00308F' },
    { label: 'Bad PR Outlier', color: 'red' },
  ];

  const designMap = {
    aes: 'a0',
    'aes-block': 'a1',
    aes_lvt: 'a2',
    'aes-mbff': 'a3',
    bp_be: 'b0',
    bp_fe: 'b1',
    bp_multi: 'b2',
    chameleon: 'c',
    dynamic_node: 'd',
    ethmac: 'e0',
    ethmac_lvt: 'e1',
    gcd: 'g',
    ibex: 'i',
    jpeg: 'j0',
    jpeg_lvt: 'j1',
    microwatt: 'm0',
    'mock-alu': 'm1',
    'mock-array': 'm2',
    riscv32i: 'r',
    sha3: 'sh',
    SramBridge: 's0',
    spi: 's1',
    swerv: 's2',
    swerv_wrapper: 's3',
    tinyRocket: 't',
    uart: 'u0',
    'uart-blocks': 'u1',
  };

  const abbreviationData = Object.entries(designMap).map(([key, value]) => ({
    meaning: key,
    abbreviation: value,
  }));

  console.log('data', dataSource);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <fieldset
        style={{
          border: '1px solid #000',
          borderColor: '#D3D3D3',
          marginBottom: '20px',
        }}
      >
        <legend style={{ color: '#A9A9A9' }}>Percentile</legend>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            marginBottom: '6px',
            marginTop: '6px',
          }}
        >
          <label
            style={{
              textAlign: 'center',
            }}
            htmlFor='cutOffInput'
          >
            Cut Off Value:
          </label>
          <InputNumber
            id='cutOffInput'
            step={1}
            defaultValue={5}
            min={0}
            max={100}
            addonAfter='%'
            value={cutOffValue}
            onChange={value => setCutOffValue(value)}
            style={{
              marginLeft: '10px',
            }}
          />
        </div>
      </fieldset>
      {dataSource && Object.keys(dataSource).length !== 0 && (
        <SummaryGrid
          dataSource={dataSource}
          selectedFilters={selectedFilters}
          filterType={filterType}
          loading={isLoading || loading}
        />
      )}
      <fieldset
        style={{
          border: '1px solid #000',
          borderColor: '#D3D3D3',
          marginBottom: '20px',
        }}
      >
        <legend style={{ color: '#A9A9A9' }}>Table Filters</legend>
        <Button
          type='primary'
          style={
            prFilterClicked
              ? {
                  backgroundColor: '#52c41a',
                  borderColor: '#52c41a',
                  marginRight: '20px',
                }
              : {
                  backgroundColor: '#ffffff',
                  borderColor: '#52c41a',
                  marginRight: '20px',
                  color: '#52c41a',
                }
          }
          onClick={handleClick}
        >
          PR Metrics
        </Button>

        <Button danger onClick={handleClearFilters}>
          Clear All
        </Button>
      </fieldset>
      <AbbreviationTable
        abbreviationData={abbreviationData}
        designMap={designMap}
      />
      <ColorLegend legendData={legendData} />
      <Spin spinning={isLoading || loading} style={{ textAlign: 'center' }}>
        {dataSource && Object.keys(dataSource).length !== 0 && (
          <GridTable
            dataSource={dataSource}
            selectedFilters={selectedFilters}
            filterType={filterType}
            legendData={legendData}
            designMap={designMap}
          ></GridTable>
        )}
      </Spin>
    </div>
  );
};

export default StatisticalSignificancePage;

/**
 * @module pages/MetricsTrendsPage
 *
 * @description
 * This module contains the `MetricsTrendsPage` component, which provides a user interface for analyzing and monitoring metrics trends across various platforms. The component utilizes tabs to switch between different platforms and displays detailed metrics for the selected platform. It interacts with API services to fetch platform data and supports user interactions such as selecting metrics and filtering platforms.
 */
import React, { useEffect, useState } from 'react';
import './MetricsTrendsPage.css';
import { useGetPlatformsQuery } from '../services/api';
import { Tabs } from 'antd';
import PlatformTab from '../components/MetricTrends/PlatformTab';

const { TabPane } = Tabs;

/**
 * MetricsTrendsPage is a React component that displays a tabbed interface for monitoring and analyzing metrics trends across various platforms.
 *
 * This component uses the `Tabs` component from Ant Design to render a tab for each platform. Each tab displays a `PlatformTab` component that is responsible for showing detailed metrics for the selected platform.
 *
 * @function MetricsTrendsPage
 * @param {Object} props - The component props.
 * @param {string} [props.branchName="master"] - The branch name to use for fetching platform-specific metrics data. Defaults to "master" if not provided.
 * @returns {JSX.Element} The rendered MetricsTrendsPage component, which includes a series of tabs for different platforms, each displaying metrics trends and allowing users to interact with them.
 */
const MetricsTrendsPage = ({ branchName = 'master' }) => {
  const [selectedPlatform, setSelectedPlatform] = useState('asap7');
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);

  const [loading, setLoading] = useState(false);

  const { data: platforms } = useGetPlatformsQuery();

  /**
   * Handles the change in the selected tab. This function updates the `selectedPlatform` state when a new tab is chosen.
   *
   * @param {string} tab - The key of the newly selected tab, representing the platform.
   */
  async function handleTabChange(tab) {
    setSelectedPlatform(tab);
  }

  const invisibleTabKeys = ['intel22', 'intel16', 'gf12', 'gf55', 'tsmc65lp'];
  useEffect(() => {
    setFilteredPlatforms(
      platforms?.filter(platform => !invisibleTabKeys.includes(platform)),
    );
  }, [platforms]);

  const [selectedMetrics, setselectedMetrics] = useState([]);
  const [defaultMetrics, setDefaultMetrics] = useState(true);
  const [clearMetrics, setClearMetrics] = useState(false);

  /**
   * Handles changes in the selected metrics. Updates the state for selected metrics and flags to manage default and clear states.
   *
   * @param {Array} value - The new array of selected metrics.
   */
  const handleMetricChange = value => {
    setDefaultMetrics(false);
    setselectedMetrics(value);
    setClearMetrics(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Tabs type='card' onChange={handleTabChange}>
        {filteredPlatforms?.map(tab => (
          <TabPane tab={tab} key={tab}>
            <PlatformTab
              tab={tab}
              key={tab}
              branchName={branchName}
              selectedPlatform={selectedPlatform}
              handleMetricChange={handleMetricChange}
              setselectedMetrics={setselectedMetrics}
              selectedMetrics={selectedMetrics}
              defaultMetrics={defaultMetrics}
              setClearMetrics={setClearMetrics}
              clearMetrics={clearMetrics}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MetricsTrendsPage;

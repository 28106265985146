/**
 * @module app/store
 *
 * Configures and creates the Redux store with the application's reducers and middleware.
 * Integrates RTK Query's API slices for caching and state management of API requests.
 */
import { configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { mainApi } from '../services/api';
import { authApi } from '../services/admin';
import authReducer from '../features/auth/authSlice';

/**
 * Creates and configures the Redux store with necessary middleware.
 *
 * @returns {Store} The Redux store.
 */
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [mainApi.reducerPath]: mainApi.reducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(mainApi.middleware, authApi.middleware),
});

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * @module services/api
 *
 * @description
 * This module provides the API service configuration using Redux Toolkit Query.
 * It defines the endpoints and corresponding hooks to interact with the backend.
 * It has all the endpoints that belong to the application layer.
 */

/**
 * The base URL for the API.
 * @constant {string}
 * @default "http://localhost:80"
 */
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : 'http://localhost:80';

/**
 * The API service created using Redux Toolkit Query.
 *
 * @typedef {Object} MainApi
 * @property {Object} endpoints - The API endpoints.
 * @property {Function} useGetPlatformsQuery - Hook for fetching platforms.
 * @property {Function} useGetBranchesCommitsQuery - Hook for fetching branch commits.
 * @property {Function} useGetDesignsQuery - Hook for fetching designs.
 * @property {Function} useGetBranchQuery - Hook for fetching branch details.
 * @property {Function} useGetCommitQuery - Hook for fetching commit details.
 * @property {Function} useGetMetricTrendsQuery - Hook for fetching metric trends.
 * @property {Function} useGetCompareQuery - Hook for comparing metrics.
 * @property {Function} useGetBranchLatestDesignsQuery - Hook for fetching branch latest designs.
 * @property {Function} useGetCommitDesignsQuery - Hook for fetching commit designs.
 * @property {Function} useGetBranchLatestQuery - Hook for fetching branch latest data.
 * @property {Function} useGetCommitMetricsQuery - Hook for fetching commit metrics.
 * @property {Function} useGetBranchCommitPlatformsQuery - Hook for fetching branch commit platforms.
 * @property {Function} useGetSourceDataQuery - Hook for fetching source data.
 * @property {Function} useGetPlatformsSummaryQuery - Hook for fetching platforms summary.
 * @property {Function} useGetBuildNumbersQuery - Hook for fetching build numbers.
 * @property {Function} useGetDistDataQuery - Hook for fetching distribution data.
 * @property {Function} useGetBranchCommitsQuery - Hook for fetching branch commits.
 * @property {Function} useGetGoldenCommitQuery - Hook for fetching the golden commit.
 */

/**
 * API service for interacting with the backend.
 *
 * @type {MainApi}
 */
export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    /**
     * Fetches the list of platforms.
     *
     * @returns {Object} The platforms data.
     */
    getPlatforms: builder.query({
      query: () => `/platforms`,
    }),
    /**
     * Fetches the list of branch commits.
     *
     * @returns {Object} The branch commits data.
     */
    getBranchesCommits: builder.query({
      query: () => `/branches_commits`,
    }),
    /**
     * Fetches designs based on platform ID.
     *
     * @param {string} id - The platform ID.
     * @returns {Array<string>} An array of design names.
     */
    getDesigns: builder.query({
      query: id => `/platform?platform=${id}`,
      transformResponse: response => {
        return Object.keys(response['designs']);
      },
      skip: ({ id }) => !id,
    }),
    /**
     * Fetches details of a specific branch.
     *
     * @param {string} branchName - The branch name.
     * @returns {Object} The branch details.
     */
    getBranch: builder.query({
      query: branchName => `/branch?branchName=${branchName}`,
      skip: ({ branchName }) => !branchName,
    }),
    /**
     * Fetches commit details based on commit SHA.
     *
     * @param {string} commitSHA - The commit SHA.
     * @returns {Object} The commit details.
     */
    getCommit: builder.query({
      query: commitSHA => `/commit?commitSHA=${commitSHA}`,
      skip: ({ commitSHA }) => !commitSHA,
    }),
    /**
     * Fetches metric trends based on various parameters.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.platform - The platform name.
     * @param {Array<string>} params.designs - The designs.
     * @param {string} params.variant - The variant.
     * @returns {Object} The metric trends data.
     */
    getMetricTrends: builder.query({
      query: ({ branchName, platform, designs, variant }) =>
        `/metric_trends?platform=${platform}&designs=${designs}&variant=${variant}&branchName=${branchName}`,
      transformResponse: response => {
        const flattenedObj = {};
        Object.keys(response).forEach(key => {
          flattenedObj[key] = response[key].flat(Infinity);
        });
        return flattenedObj;
      },
      skip: ({ branchName, platform, designs, variant }) =>
        !branchName ||
        !platform ||
        Object.key(designs).length === 0 ||
        !designs ||
        !variant,
    }),
    /**
     * Compares metrics between two sources.
     *
     * @param {Object} data - The comparison data.
     * @returns {Object} The comparison result with sorted metrics.
     */
    getCompare: builder.query({
      query: data => ({
        url: '/compare', // Replace with your API endpoint
        method: 'LIST',
        body: data,
      }),
      transformResponse: response => {
        const metrics = response?.metrics;
        metrics?.sort((a, b) => {
          const nameA = a?.metrics?.toUpperCase();
          const nameB = b?.metrics?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        return {
          compare: response?.compare,
          metrics: metrics,
        };
      },
      skip: ({
        sourceAName,
        sourceAType,
        sourceBName,
        sourceBType,
        platform,
        designs,
        variant,
      }) =>
        !sourceAName ||
        !sourceAType ||
        !sourceBName ||
        !sourceBType ||
        !platform ||
        !designs,
    }),
    /**
     * Fetches the latest designs for a branch.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.platform - The platform name.
     * @param {Array<string>} params.designs - The designs.
     * @param {string} params.variant - The variant.
     * @returns {Object} The latest designs data.
     */
    getBranchLatestDesigns: builder.query({
      query: ({ branchName, platform, designs, variant }) =>
        `/branchlatest_designs?platform=${platform}&designs=${designs}&variant=${variant}&branchName=${branchName}`,
    }),
    /**
     * Fetches the designs associated with a specific commit.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.commitSHA - The commit SHA.
     * @param {string} params.platform - The platform name.
     * @param {Array<string>} params.designs - The designs.
     * @param {string} params.variant - The variant.
     * @returns {Object} The commit designs data.
     */
    getCommitDesigns: builder.query({
      query: ({ commitSHA, platform, designs, variant }) =>
        `/commit_designs?platform=${platform}&designs=${designs}&variant=${variant}&commitSHA=${commitSHA}`,
    }),
    /**
     * Fetches the latest data for a branch.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.platform - The platform name.
     * @param {string} params.design - The design.
     * @param {string} params.variant - The variant.
     * @returns {Object} The latest branch data.
     */
    getBranchLatest: builder.query({
      query: ({ branchName, platform, design, variant }) => ({
        url: '/branchlatest',
        method: 'GET',
        params: { branchName, platform, design, variant },
      }),
    }),
    /**
     * Fetches metrics associated with a specific commit.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.commitSHA - The commit SHA.
     * @param {string} params.platform - The platform name.
     * @param {string} params.design - The design.
     * @param {string} params.variant - The variant.
     * @returns {Object} The commit metrics data.
     */
    getCommitMetrics: builder.query({
      query: ({ commitSHA, platform, design, variant }) => ({
        url: '/commit',
        method: 'GET',
        params: { commitSHA, platform, design, variant },
      }),
    }),
    /**
     * Fetches the platforms associated with a branch and commit SHA.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.commitSHA - The commit SHA.
     * @returns {Object} The branch commit platforms data.
     */
    getBranchCommitPlatforms: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: '/branch_platforms',
        method: 'GET',
        params: { branchName, commitSHA },
      }),
    }),
    /**
     * Fetches source (base/test) data associated with a branch and commit SHA.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.commitSHA - The commit SHA.
     * @returns {Object} The source data.
     */
    getSourceData: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: '/sourceData',
        method: 'GET',
        params: { branchName, commitSHA },
      }),
    }),
    /**
     * Fetches summary data for platforms.
     *
     * @param {Object} data - The summary data request.
     * @returns {Object} The platforms summary data.
     */
    getPlatformsSummary: builder.query({
      query: data => ({
        url: '/platforms/summary', // Replace with your API endpoint
        method: 'LIST',
        body: data,
      }),
    }),
    /**
     * Fetches build numbers for a branch and commit SHA.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @param {string} params.commitSHA - The commit SHA.
     * @returns {Object} The build numbers data.
     */
    getBuildNumbers: builder.query({
      query: ({ branchName, commitSHA }) => ({
        url: '/build_numbers',
        method: 'GET',
        params: { branchName, commitSHA },
      }),
    }),
    /**
     * Fetches distribution data based on cut-off value and force update flag.
     *
     * @param {Object} params - The query parameters.
     * @param {number} params.cutOffValue - The cut-off value.
     * @param {boolean} params.forceUpdate - Whether to force update.
     * @returns {Object} The distribution data.
     */
    getDistData: builder.query({
      query: ({ cutOffValue, forceUpdate }) => ({
        url: '/dist',
        method: 'GET',
        params: { cutOffValue, forceUpdate },
      }),
      transformResponse: response => {
        const data = response?.data;
        data?.sort((a, b) => {
          const nameA = a?.metric?.toUpperCase();
          const nameB = b?.metric?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        return {
          ...response,
          data: data,
        };
      },
    }),
    /**
     * Fetches commits for a specific branch.
     *
     * @param {Object} params - The query parameters.
     * @param {string} params.branchName - The branch name.
     * @returns {Object} The branch commits data.
     */
    getBranchCommits: builder.query({
      query: ({ branchName }) => ({
        url: '/branch_commits',
        method: 'GET',
        params: { branchName },
      }),
    }),
    /**
     * Fetches the golden commit.
     *
     * @returns {Object} The golden commit data.
     */
    getGoldenCommit: builder.query({
      query: () => ({
        url: '/golden',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDesignsQuery,
  useGetPlatformsQuery,
  useGetBranchLatestQuery,
  useLazyGetBranchLatestQuery,
  useGetMetricTrendsQuery,
  useLazyGetMetricTrendsQuery,
  useGetCommitMetricsQuery,
  useLazyGetCommitMetricsQuery,
  useGetBranchCommitPlatformsQuery,
  useGetBranchesCommitsQuery,
  useGetBranchLatestDesignsQuery,
  useGetCommitDesignsQuery,
  useGetCompareQuery,
  useGetBranchQuery,
  useGetCommitQuery,
  useGetSourceDataQuery,
  useGetPlatformsSummaryQuery,
  useGetBuildNumbersQuery,
  useGetDistDataQuery,
  useGetBranchCommitsQuery,
  useGetGoldenCommitQuery,
} = mainApi;
